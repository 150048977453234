import ModalForm from 'components/ModalForm';
import { Customer } from 'queryTypes/customer';
import { useCallback, useMemo, useState } from 'react';
import { Camembert, Bar, Equivalent, Intensity, Year } from 'queryTypes/stat/stat.model';
import ModalEditStatisticItem from './Item';

type Props = {
  customer: Customer;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    camembert: Camembert,
    bar: Bar,
    equivalent: Equivalent,
    intensity: Intensity,
    year: Year
  ) => void;
};
const ModalEditStatistic = (props: Props) => {
  const { customer, isOpen, onClose, onSubmit } = props;

  // Init Camembert
  const camembertInit: Camembert = useMemo(() => {
    return customer.forms[0]?.stats[0]?.camembert
      ? JSON.parse(customer.forms[0].stats[0].camembert as unknown as string)
      : {
          total: 0,
          data: [
            {
              label: '',
              percent: 0
            }
          ]
        };
  }, [customer]);

  // Init Bar
  const barInit: Bar = useMemo(() => {
    return customer.forms[0]?.stats[0]?.bar
      ? JSON.parse(customer.forms[0].stats[0].bar as unknown as string)
      : {
          objectif: {
            2022: 0,
            2023: 0,
            2027: 0,
            2032: 0
          },
          emission: {
            2022: 0,
            2023: 0,
            2027: 0,
            2032: 0
          },
          scenario_without_action: {
            2022: 0,
            2023: 0,
            2027: 0,
            2032: 0
          },
          scenario_with_action: {
            2022: 0,
            2023: 0,
            2027: 0,
            2032: 0
          }
        };
  }, [customer]);

  // Init Equivalent
  const equivalentInit: Equivalent = useMemo(() => {
    return customer.forms[0]?.stats[0]?.equivalent
      ? JSON.parse(customer.forms[0].stats[0].equivalent as unknown as string)
      : {
          forest: 0,
          roundTrip: 0,
          worldTour: 0,
          perPerson: 0
        };
  }, [customer]);

  // Init Intensity
  const intensityInit: Intensity = useMemo(() => {
    return customer.forms[0]?.stats[0]?.intensity
      ? JSON.parse(customer.forms[0].stats[0].intensity as unknown as string)
      : {
          nbKgCO2eEuro: 0,
          nbKgCO2eUnit: 0,
          unit: ''
        };
  }, [customer]);

  // Init Year
  const yearInit: Year = useMemo(() => {
    return customer.forms[0]?.stats[0]?.year
      ? JSON.parse(customer.forms[0].stats[0].year as unknown as string)
      : {
          date: 0
        };
  }, [customer]);

  const [camembert, setCamembert] = useState<Camembert>(camembertInit);
  const [bar, setBar] = useState<Bar>(barInit);
  const [equivalent, setEquivalent] = useState<Equivalent>(equivalentInit);
  const [intensity, setIntensity] = useState<Intensity>(intensityInit);
  const [year, setYear] = useState<Year>(yearInit);

  const handleDelete = useCallback(
    (item: any) => () => {
      setCamembert({
        ...camembert,
        data: camembert.data.filter((data: any) => data !== item)
      });
    },
    [camembert]
  );

  const handleChangeLabel = useCallback(
    (item: any, label: string) => {
      setCamembert({
        ...camembert,
        data: camembert.data.map((data: any) => {
          if (data === item) {
            return { ...data, label: label };
          }
          return data;
        })
      });
    },
    [camembert]
  );

  const handleChangeValue = useCallback(
    (item: any, value: number) => {
      setCamembert({
        ...camembert,
        data: camembert.data.map((data: any) => {
          if (data === item) {
            return { ...data, percent: value };
          }
          return data;
        })
      });
    },
    [camembert]
  );

  return (
    <ModalForm
      title={`Modifiez les statistiques`}
      ctaTitle="Enregistrer"
      size="xl"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit(camembert, bar, equivalent, intensity, year)}
      withCloseButton
    >
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Date</h2>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Année du bilan carbone
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  onChange={(e) => setYear({ ...year, date: Number(e.target.value) })}
                  value={year.date}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Camembert</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Le camembert représente la répartition des émissions de gaz à effet de serre par
              catégorie.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-4">
              <label htmlFor="total" className="block text-sm font-medium leading-6 text-gray-900">
                Total
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  onChange={(e) => setCamembert({ ...camembert, total: Number(e.target.value) })}
                  value={camembert.total}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <div>
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <p className="mt-2 text-sm text-gray-700">
                      Ajouter des nouveaux labels pour alimenter le camembert.
                    </p>
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                      type="button"
                      className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() =>
                        setCamembert({
                          ...camembert,
                          data: [...camembert.data, { label: '', percent: 0 }]
                        })
                      }
                    >
                      Ajouter une ligne
                    </button>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                              Label
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Pourcentage
                            </th>
                            <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                              <span className="sr-only">Supprimer</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {camembert.data.map((item: any, index: number) => (
                            <ModalEditStatisticItem
                              key={index}
                              data={item}
                              onDelete={handleDelete(item)}
                              onChangeLabel={(item: any, label: string) =>
                                handleChangeLabel(item, label)
                              }
                              onChangeValue={(item: any, value: number) =>
                                handleChangeValue(item, value)
                              }
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Trajectoire de réduction des émissions
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Limiter le changement climatique nécessite de réduire nos émissions de gaz à effet de
              serre dans les prochaines années. Les objectifs pour chaque entreprise sont fixés par
              des standards internationaux en fonction du secteur d&apos;activité. Ces objectifs
              peuvent concerner une réduction du total des émissions de gaz à effet de serre ou une
              réduction de l&apos;intensité carbone de l&apos;entreprise. Les bilans carbone
              successifs permettent d&apos;apprécier la trajectoire de l&apos;entreprise par rapport
              à ses objectifs.
            </p>
          </div>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <h1 className="font-bold mb-4">Objectif</h1>
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      2022
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2023
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2027
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2032
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            objectif: { ...prevBar.objectif, 2022: Number(e.target.value) }
                          }))
                        }
                        value={bar.objectif[2022]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            objectif: { ...prevBar.objectif, 2023: Number(e.target.value) }
                          }))
                        }
                        value={bar.objectif[2023]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            objectif: { ...prevBar.objectif, 2027: Number(e.target.value) }
                          }))
                        }
                        value={bar.objectif[2027]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            objectif: { ...prevBar.objectif, 2032: Number(e.target.value) }
                          }))
                        }
                        value={bar.objectif[2032]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <h1 className="font-bold mb-4">Émission</h1>
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      2022
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2023
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2027
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2032
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            emission: { ...prevBar.emission, 2022: Number(e.target.value) }
                          }))
                        }
                        value={bar.emission[2022]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            emission: { ...prevBar.emission, 2023: Number(e.target.value) }
                          }))
                        }
                        value={bar.emission[2023]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            emission: { ...prevBar.emission, 2027: Number(e.target.value) }
                          }))
                        }
                        value={bar.emission[2027]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            emission: { ...prevBar.emission, 2032: Number(e.target.value) }
                          }))
                        }
                        value={bar.emission[2032]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <h1 className="font-bold mb-4">Scénario sans plan d&apos;action</h1>
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      2022
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2023
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2027
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2032
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            scenario_without_action: {
                              ...prevBar.scenario_without_action,
                              2022: Number(e.target.value)
                            }
                          }))
                        }
                        value={bar.scenario_without_action[2022]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            scenario_without_action: {
                              ...prevBar.scenario_without_action,
                              2023: Number(e.target.value)
                            }
                          }))
                        }
                        value={bar.scenario_without_action[2023]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            scenario_without_action: {
                              ...prevBar.scenario_without_action,
                              2027: Number(e.target.value)
                            }
                          }))
                        }
                        value={bar.scenario_without_action[2027]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            scenario_without_action: {
                              ...prevBar.scenario_without_action,
                              2032: Number(e.target.value)
                            }
                          }))
                        }
                        value={bar.scenario_without_action[2032]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <h1 className="font-bold mb-4">Scénario avec plan d&apos;action</h1>
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      2022
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2023
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2027
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      2032
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            scenario_with_action: {
                              ...prevBar.scenario_with_action,
                              2022: Number(e.target.value)
                            }
                          }))
                        }
                        value={bar.scenario_with_action[2022]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            scenario_with_action: {
                              ...prevBar.scenario_with_action,
                              2023: Number(e.target.value)
                            }
                          }))
                        }
                        value={bar.scenario_with_action[2023]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            scenario_with_action: {
                              ...prevBar.scenario_with_action,
                              2027: Number(e.target.value)
                            }
                          }))
                        }
                        value={bar.scenario_with_action[2027]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setBar((prevBar) => ({
                            ...prevBar,
                            scenario_with_action: {
                              ...prevBar.scenario_with_action,
                              2032: Number(e.target.value)
                            }
                          }))
                        }
                        value={bar.scenario_with_action[2032]}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              C&apos;est l&apos;équivalent de...
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Les statistiques Équivalent représentent les informations sur les équivalents (m²
              Forêt...).
            </p>
          </div>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      m² de Forêt mature en France
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Aller-retours Paris / New-York en avion
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Tours du monde en voiture diesel
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Nombre par personnes
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setEquivalent((prevEquivalent) => ({
                            ...prevEquivalent,
                            forest: Number(e.target.value)
                          }))
                        }
                        value={equivalent.forest}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setEquivalent((prevEquivalent) => ({
                            ...prevEquivalent,
                            roundTrip: Number(e.target.value)
                          }))
                        }
                        value={equivalent.roundTrip}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setEquivalent((prevEquivalent) => ({
                            ...prevEquivalent,
                            worldTour: Number(e.target.value)
                          }))
                        }
                        value={equivalent.worldTour}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setEquivalent((prevEquivalent) => ({
                            ...prevEquivalent,
                            perPerson: Number(e.target.value)
                          }))
                        }
                        value={equivalent.perPerson}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Intensité carbonne</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Les indicateurs d&apos;intensité carbone permettent de rendre le bilan carbone
              comparable à un secteur d&apos;activité ou à d&apos;autres entreprises. Ils sont
              calculés en rapportant le total des émissions de gaz à effet de serre de
              l&apos;entreprise à un autre indicateur chiffré reflétant la taille de
              l&apos;entreprise.
            </p>
          </div>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      kgCO2e / € C.A.
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      kgCO2e / Unité.
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        type="number"
                        onChange={(e) =>
                          setIntensity((prevIntensity) => ({
                            ...prevIntensity,
                            nbKgCO2eEuro: Number(e.target.value)
                          }))
                        }
                        value={intensity.nbKgCO2eEuro}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <tr>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <input
                          type="number"
                          onChange={(e) =>
                            setIntensity((prevIntensity) => ({
                              ...prevIntensity,
                              nbKgCO2eUnit: Number(e.target.value)
                            }))
                          }
                          value={intensity.nbKgCO2eUnit}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <input
                          type="text"
                          onChange={(e) =>
                            setIntensity((prevIntensity) => ({
                              ...prevIntensity,
                              unit: String(e.target.value)
                            }))
                          }
                          value={intensity.unit}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </td>
                    </tr>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalEditStatistic;
