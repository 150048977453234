import { ApolloClient, useMutation } from '@apollo/client';
import { CheckCircleIcon, RocketLaunchIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Loading from 'components/Loading';
import { Form } from 'queryTypes/form';
import { Question } from 'queryTypes/question';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useDestroyQuestion,
  useDuplicateQuestion,
  useUpdateQuestion
} from 'services/api/question/queries';
import questionService from 'services/api/question/services';
import FormsStepQuestionsItem from './Item';
import FormsStepQuestionsNewQuestion from './NewQuestion';
import { Type } from 'enums/type';

type Props = {
  form: Form;
  onSubmit: () => void;
  disabled: boolean;
  client: ApolloClient<any>;
};

const FormsStepQuestions = (props: Props) => {
  const { form, onSubmit, disabled, client } = props;
  const [enableAlert, setEnableAlert] = useState<boolean>(true);
  const [nbQuestions, setNbQuestions] = useState<number>(0);

  // Query question
  const {
    data: questions,
    error,
    refetch,
    loading: isLoadingQuestion
  } = questionService.getAll({
    formId: form.id,
    orderByField: 'orderIndex',
    orderByDirection: 'ASC'
  });

  const [destroyQuestion, { loading: isLoadingDestroyQuestion }] = useMutation(useDestroyQuestion);
  const [updateQuestion, { loading: isLoadingUpdateQuestion }] = useMutation(useUpdateQuestion);
  const [duplicateQuestion, { loading: loadingDuplicateQuestion }] =
    useMutation(useDuplicateQuestion);

  const handleDestroyQuestion = useCallback(
    (question: Question) => {
      if (isLoadingDestroyQuestion) return;
      destroyQuestion({
        variables: {
          questionId: question.id
        },
        onCompleted: (data) => {
          if (data && !data.destroyQuestion.errors) {
            setNbQuestions(nbQuestions - 1);
            refetch();
            client.restore('forms');
          }
        }
      });
    },
    [form.id, isLoadingDestroyQuestion]
  );

  const handleChangePosition = useCallback(
    (question: Question, position: number) => {
      if (isLoadingUpdateQuestion) return;
      let orderIndexOrientation = 'ASC';

      if (!question) return;
      if (position < question.orderIndex) {
        orderIndexOrientation = 'ASC';
      } else {
        orderIndexOrientation = 'DESC';
      }

      questions.questions.forEach((questionLoop: Question) => {
        let orderIndex = questionLoop.orderIndex;

        if (question.id === questionLoop.id) {
          orderIndex = position;
        } else {
          if (orderIndexOrientation === 'ASC') {
            if (questionLoop.orderIndex === position) {
              orderIndex = position + 1;
            }
          }
          if (orderIndexOrientation === 'DESC') {
            if (questionLoop.orderIndex === position) {
              orderIndex = position - 1;
            }
          }
        }

        if (orderIndex !== questionLoop.orderIndex) {
          updateQuestion({
            variables: {
              questionId: questionLoop.id,
              question: {
                orderIndex: orderIndex
              }
            },
            onCompleted: (data) => {
              if (data && !data.updateQuestion.errors) {
                refetch();
              }
            }
          });
        }
      });
    },
    [questions, isLoadingUpdateQuestion]
  );

  const handleUpdate = useCallback(
    (
      question: Question,
      text: string,
      helpMessage: string,
      estimatedTimeToAnswer: number,
      answerType: Type.INTEGER | Type.STRING | Type.BOOLEAN | Type.MULTIPLE_CHOICES | Type.SHEET,
      sheetTemplate: string,
      sheetFormula: string
    ) => {
      if (isLoadingUpdateQuestion) return;
      updateQuestion({
        variables: {
          questionId: question.id,
          question: {
            text,
            helpMessage,
            estimatedTimeToAnswer,
            answerType,
            sheetTemplate,
            sheetFormula
          }
        },
        onCompleted: (data) => {
          if (data && !data.updateQuestion.errors) {
            refetch();
          }
        }
      });
    },
    [isLoadingUpdateQuestion]
  );

  const handleDuplicateQuestion = useCallback((question: Question, text: string) => {
    if (loadingDuplicateQuestion) return;
    duplicateQuestion({
      variables: {
        questionId: question.id,
        text
      },
      onCompleted: (data) => {
        if (data && !data.duplicateQuestion.errors) {
          refetch();
        }
      }
    });
  }, []);

  const answerTypeChoices = useMemo(() => {
    return [
      {
        label: 'Texte libre',
        value: 'string'
      },
      {
        label: 'Nombre libre',
        value: 'integer'
      },
      {
        label: 'Oui / Non',
        value: 'boolean'
      },
      {
        label: 'Choix multiples',
        value: 'multiple_choices'
      },
      {
        label: 'Tableau',
        value: 'sheet'
      }
    ];
  }, []);

  useEffect(() => {
    if (nbQuestions === 0 && questions && questions.questions.length > 0) {
      setNbQuestions(questions.questions.length);
    }

    // Permets de réattribuer automatiquement la bonne position à chaque question
    if (
      questions &&
      questions.questions.length > 0 &&
      !isLoadingQuestion &&
      !isLoadingUpdateQuestion &&
      !isLoadingDestroyQuestion &&
      !loadingDuplicateQuestion
    ) {
      questions.questions.forEach((question: Question, index: number) => {
        if (question.orderIndex !== index + 1) {
          updateQuestion({
            variables: {
              questionId: question.id,
              question: {
                orderIndex: index + 1
              }
            },
            onCompleted: (data) => {
              if (data && !data.updateQuestion.errors) {
                refetch();
              }
            }
          });
        }
      });
    }
  }, [questions, isLoadingQuestion, isLoadingUpdateQuestion, isLoadingDestroyQuestion]);

  if (error) return <div>Erreur</div>;
  return (
    <>
      <div className="max-w-6xl mx-auto">
        {(isLoadingQuestion || isLoadingDestroyQuestion || isLoadingUpdateQuestion) && <Loading />}
        {enableAlert && !disabled && (
          <div className="rounded-md bg-green-50 p-4 mb-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">Questionnaire créé</h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>
                    Le questionnaire portant sur le secteur d&apos;activité &quot;
                    <strong>{form.sector}</strong>&quot; a bien été créé.
                  </p>
                  <p>Il est temps d&apos;ajouter vos questions à votre questionnaire</p>
                </div>
              </div>
              <div className="ml-auto">
                <XMarkIcon
                  className="h-5 w-5 text-gray-600 cursor-pointer"
                  onClick={() => setEnableAlert(false)}
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        )}
        <FormsStepQuestionsNewQuestion
          form={form}
          setNbQuestions={setNbQuestions}
          nbQuestions={nbQuestions}
          client={client}
          refetch={refetch}
        />
      </div>
      <div className="max-w-6xl mx-auto">
        <div className="mt-8 mb-10 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                    >
                      Position
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Texte
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Valeurs possibles
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Règles
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Supprimer</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {questions?.questions.map((question: Question, index: number) => (
                    <FormsStepQuestionsItem
                      key={index}
                      question={question}
                      answerTypeChoices={answerTypeChoices}
                      onDestroy={handleDestroyQuestion}
                      onChangePosition={handleChangePosition}
                      onUpdate={handleUpdate}
                      onDuplicate={handleDuplicateQuestion}
                      refetch={refetch}
                      isLoading={
                        isLoadingQuestion || isLoadingDestroyQuestion || isLoadingUpdateQuestion
                      }
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center">
          <button
            className={`mb-10 inline-flex items-center bg-blue-500 text-white font-bold py-2 px-4 rounded ${
              questions?.questions.find(
                (question: Question) => question.answerType === 'multiple_choices'
              )?.possibleAnswers.length === 0 || nbQuestions === 0
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-blue-700'
            }`}
            disabled={
              questions?.questions.find(
                (question: Question) => question.answerType === 'multiple_choices'
              )?.possibleAnswers.length === 0 || nbQuestions === 0
            }
            onClick={onSubmit}
          >
            <RocketLaunchIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            J&apos;ai terminé
          </button>
        </div>
      </div>
    </>
  );
};

export default FormsStepQuestions;
