import { runInAction, makeAutoObservable } from 'mobx';
import { Workspace } from 'queryTypes/workspace';
import { ApolloClient, gql } from '@apollo/client';

const initialOrganization: Workspace | null = (() => {
  try {
    return JSON.parse(window.localStorage.getItem('currentOrganization') || 'null');
  } catch (error) {
    return null;
  }
})();

class OrganizationStore {
  currentOrganizationId: string | null = initialOrganization?.id || null;

  currentOrganization: Workspace | null = initialOrganization
    ? {
        id: initialOrganization.id,
        name: initialOrganization.name,
        users: initialOrganization.users,
        forms: initialOrganization.forms,
        customers: initialOrganization.customers,
        updatedAt: initialOrganization.updatedAt
      }
    : null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchOrganizations(clientApollo: ApolloClient<any>) {
    if (!this.currentOrganizationId) return;

    try {
      clientApollo
        .query({
          query: gql`
          query getWorkspace {
            workspace(id: "${this.currentOrganizationId}") {
              id
              name
              users {
                id
                firstName
                lastName
              }
              forms {
                id
                sector
              }
              customers {
                id
                name
              }
            }
          }
        `,
          fetchPolicy: 'no-cache'
        })
        .then((result: any) => {
          window.localStorage.setItem(
            'currentOrganization',
            JSON.stringify({
              ...result.data.workspace
            })
          );
          runInAction(() => {
            this.currentOrganization = result.data.workspace;
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  setCurrent(organizationId: string, clientApollo: ApolloClient<any>) {
    if (this.currentOrganizationId === organizationId) return;

    this.currentOrganizationId = organizationId;

    this.fetchOrganizations(clientApollo);
  }

  resetCurrent() {
    this.currentOrganization = null;
  }
}

export default new OrganizationStore();
export { OrganizationStore };
