import Loading from 'components/Loading';
import { FilterCustomer } from 'queryTypes/customer';
import { useState } from 'react';
import customerService from 'services/api/customer/services';
import formService from 'services/api/forms/services';
import questionService from 'services/api/question/services';
import organizationStore from 'stores/Organization';

const Home = () => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const [filter, setFilter] = useState<FilterCustomer>({
    workspaceId: currentOrganization?.id,
    orderByField: 'createdAt',
    orderByDirection: 'DESC'
  });

  // Query customers
  const {
    data: customers,
    error: errorCustomer,
    loading: isLoadingCustomer
  } = customerService.getAll(filter);

  // Query forms
  const { data: forms, error: errorForm, loading: isLoadingForm } = formService.getAll(filter);

  // Query questions
  const {
    data: questions,
    error: errorQuestion,
    loading: isLoadingQuestion
  } = questionService.getAll({
    orderByDirection: filter.orderByDirection,
    orderByField: filter.orderByField
  });

  const stats = [
    { name: 'Clients', value: customers?.customers?.length },
    { name: 'Questionnaires', value: forms?.forms?.length },
    { name: 'Questions', value: questions?.questions?.length }
  ];

  if (errorCustomer || errorForm || errorQuestion) {
    return <div>Erreur lors du chargement des statistiques</div>;
  }

  return (
    <>
      {(isLoadingCustomer || isLoadingForm || isLoadingQuestion) && <Loading />}
      <div className="bg-white">
        <div className="flex flex-row justify-between items-center mx-auto px-20 py-10">
          <h1 className="text-3xl font-normal text-black">Bonjour,</h1>
        </div>
        <div className="bg-gray-600 max-w-5xl mx-auto">
          <div className="mx-auto max-w-5xl">
            <div className="grid grid-cols-1 gap-px bg-gray-400 sm:grid-cols-2 lg:grid-cols-3">
              {stats.map((stat) => (
                <div key={stat.name} className="bg-gray-600 px-4 py-6 sm:px-6 lg:px-8">
                  <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
                  <p className="mt-2 flex items-baseline gap-x-2">
                    <span className="text-4xl font-semibold tracking-tight text-white">
                      {stat.value}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
