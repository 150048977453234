import { observer } from 'mobx-react';
import { ApolloClient } from '@apollo/client';
import authStore from 'stores/Auth';
import Layout from 'layout';
import organizationStore from 'stores/Organization';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Workspace } from 'queryTypes/workspace';
import { useCallback, useEffect, useState } from 'react';
import workspaceService from 'services/api/workspaces/services';
import Loading from 'components/Loading';

type Props = {
  clientApollo: ApolloClient<any>;
};

const OrganizationInit = ({ clientApollo }: Props): JSX.Element => {
  authStore.login(clientApollo);
  const { currentOrganization } = organizationStore;

  const [workspaceId, setWorkspaceId] = useState<string>(currentOrganization?.id || '');
  const [workspaceName, setWorkspaceName] = useState<string>(
    currentOrganization?.name || 'Sélectionnez une organisation'
  );
  const {
    data: workspaces,
    loading,
    refetch
  } = workspaceService.getAllWorkspace({ userId: authStore.currentUser?.id || '' });

  const selectWorkspace = useCallback(async (id: string, isCreate?: boolean) => {
    await organizationStore.setCurrent(id, clientApollo);
    if (isCreate) {
      refetch();
    }
  }, []);

  const handleChangeWorkspace = useCallback(
    (id: string, name: string, isCreate?: boolean) => {
      setWorkspaceId(id);
      setWorkspaceName(name);
      selectWorkspace(id, isCreate);
    },
    [setWorkspaceId, setWorkspaceName, selectWorkspace, workspaceId, workspaceName]
  );

  useEffect(() => {
    setWorkspaceName(currentOrganization?.name || 'Sélectionnez une organisation ');
  }, [currentOrganization]);

  if (loading) return <Loading />;

  return (
    <Layout client={clientApollo} withNavigation={false}>
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">Bienvenue,</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Aucune organisation sélectionnée
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Vous devez sélectionner une organisation pour accéder aux données de
                  l&apos;application
                </p>
              </div>
            </div>
            <ul
              role="list"
              className="h-24 divide-y divide-gray-100 overflow-hidden bg-white shadow-xl ring-1 ring-gray-900/5 sm:rounded-xl cursor-pointer"
            >
              {workspaces.workspaces.map((workspaceData: Workspace, index: number) => (
                <li
                  key={index}
                  className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
                  onClick={() => {
                    handleChangeWorkspace(workspaceData.id, workspaceData.name);
                  }}
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        <span className="absolute inset-x-0 -top-px bottom-0" />
                        {workspaceData.name}
                      </p>
                      <p className="mt-1 flex text-xs leading-5 text-gray-500">
                        {workspaceData.users.length} membre(s)
                      </p>
                    </div>
                  </div>
                  <div className="flex shrink-0 items-center gap-x-4">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      <p className="text-sm leading-6 text-gray-900">
                        {workspaceData.customers.length} client(s)
                      </p>
                      <p className="mt-1 text-xs leading-5 text-gray-500">
                        {workspaceData.forms.length} questionnaire(s)
                      </p>
                    </div>
                    <ChevronRightIcon
                      className="h-5 w-5 flex-none text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default observer(OrganizationInit);
