import { Transition, Menu } from '@headlessui/react';
import LogoutButton from 'components/Authentication/Logout';
import { useCallback, Fragment } from 'react';
import { Link } from 'react-router-dom';

const NavUser = (): JSX.Element => {
  const classNames = useCallback((...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  }, []);

  const userNavigation: { name: string; to: string }[] = [];

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-30 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {userNavigation.map((item) => (
          <Menu.Item key={item.name}>
            {({ active }) => (
              <Link
                to={item.to}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-black'
                )}
              >
                {item.name}
              </Link>
            )}
          </Menu.Item>
        ))}
        <LogoutButton />
      </Menu.Items>
    </Transition>
  );
};

export default NavUser;
