import { useMutation } from '@apollo/client';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ComboBox from 'components/ComboBox';
import Loading from 'components/Loading';
import ModalDelete from 'components/ModalDelete';
import ModalForm from 'components/ModalForm';
import { PossibleAnswer } from 'queryTypes/possibleAnswer';
import { Question } from 'queryTypes/question';
import { Fragment, useCallback, useState } from 'react';
import {
  useCreatePossibleAnswer,
  useDestroyPossibleAnswer
} from 'services/api/possibleAnswer/queries';
import possibleAnswerService from 'services/api/possibleAnswer/services';
import { uniqueArray } from 'utils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  question: Question;
  refetch: () => void;
};
const ModalAddPossibleAnswer = (props: Props) => {
  const { isOpen, onClose, onSubmit, question, refetch } = props;

  const [text, setText] = useState<string>('');
  const [valueExcel, setValueExcel] = useState<string>('');
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);

  // Query possibleAnswer
  const {
    data: possibleAnswers,
    error,
    refetch: refetchPossibleAnswers,
    loading: isLoadingPossibleAnswer
  } = possibleAnswerService.getAll({});

  const [createPossibleAnswer, { loading: isLoadingCreatePossibleAnswer }] =
    useMutation(useCreatePossibleAnswer);
  const [destroyPossibleAnswer, { loading: isLoadingDestroyPossibleAnswer }] =
    useMutation(useDestroyPossibleAnswer);

  const handleCreatePossibleAnswer = useCallback(() => {
    if (isLoadingCreatePossibleAnswer) return;
    createPossibleAnswer({
      variables: {
        possibleAnswer: {
          questionId: question.id,
          value: valueExcel,
          label: text
        }
      },
      onCompleted: (data) => {
        if (data && !data.createPossibleAnswer.errors) {
          setText('');
          setValueExcel('');
          refetch();
          refetchPossibleAnswers();
        }
      }
    });
  }, [question, text, valueExcel, isLoadingCreatePossibleAnswer]);

  const handleDestroyPossibleAnswer = useCallback(
    (possibleAnswer: PossibleAnswer) => {
      if (isLoadingDestroyPossibleAnswer) return;
      destroyPossibleAnswer({
        variables: {
          possibleAnswerId: possibleAnswer.id
        },
        onCompleted: (data) => {
          if (data && !data.destroyPossibleAnswer.errors) {
            refetch();
            refetchPossibleAnswers();
          }
        }
      });
    },
    [isLoadingDestroyPossibleAnswer]
  );

  if (error) return <div>Erreur: {error.message}</div>;

  return (
    <ModalForm
      title={`Ajoutez des réponses à la question "${question.text}"`}
      ctaTitle="J'ai terminé"
      size="xl"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit()}
    >
      <div className="flex flex-col space-y-5">
        <div className="flex flex-row justify-between items-center space-x-4">
          <div className="w-full">
            {!isLoadingPossibleAnswer ? (
              <ComboBox
                label="Label (Client)"
                selected={text}
                onChange={(value) => {
                  setText(value);
                  const possibleAnswer: PossibleAnswer = possibleAnswers.possibleAnswers.find(
                    (possibleAnswer: PossibleAnswer) => possibleAnswer.label === value
                  );
                  setValueExcel(possibleAnswer?.value || '');
                }}
                options={uniqueArray(possibleAnswers?.possibleAnswers, ['label'], true)?.map(
                  (possibleAnswer: any) => ({
                    label: possibleAnswer.label,
                    value: possibleAnswer.label
                  })
                )}
                withSearchOnChange
              />
            ) : (
              <Loading />
            )}
          </div>
          <div className="w-full">
            {!isLoadingPossibleAnswer ? (
              <ComboBox
                label="Valeur (Excel)"
                selected={valueExcel}
                onChange={(value) => setValueExcel(value)}
                options={uniqueArray(possibleAnswers?.possibleAnswers, ['value'], true)?.map(
                  (possibleAnswer: any) => ({
                    label: possibleAnswer.value,
                    value: possibleAnswer.value
                  })
                )}
                withSearchOnChange
              />
            ) : (
              <Loading />
            )}
          </div>
        </div>
        {text && valueExcel && (
          <div className="flex justify-end items-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleCreatePossibleAnswer}
            >
              Créer la réponse
            </button>
          </div>
        )}
        <div className="mt-8 mb-10 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Label (Client)
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Valeur (Excel)
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Supprimer</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {question?.possibleAnswers.map(
                    (possibleAnswer: PossibleAnswer, index: number) => (
                      <Fragment key={index}>
                        <tr className="hidden">
                          <td>
                            <ModalDelete
                              title="Supprimer une réponse"
                              isOpen={isOpenModalDelete}
                              onClose={() => setIsOpenModalDelete(false)}
                              onSubmit={() => handleDestroyPossibleAnswer(possibleAnswer)}
                            >
                              la réponse {possibleAnswer.label}
                              <br />
                              (valeur Excel: {possibleAnswer.value})
                            </ModalDelete>
                          </td>
                        </tr>
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {possibleAnswer.label}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {possibleAnswer.value}
                          </td>
                          <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button
                              onClick={() => setIsOpenModalDelete(true)}
                              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            >
                              <XMarkIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                              Supprimer
                            </button>
                          </td>
                        </tr>
                      </Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalAddPossibleAnswer;
