import { useState } from 'react';

type Props = {
  onSubmit: (selected: string) => void;
  defaultSelected?: string;
};

const FormsStepSectors = (props: Props) => {
  const { onSubmit, defaultSelected } = props;
  const [selected, setSelected] = useState<string>(defaultSelected || '');

  return (
    <div className="max-w-6xl mx-auto">
      <div className="mb-10">
        <input
          type="text"
          name="name"
          onChange={(e) => setSelected(e.target.value)}
          value={selected}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Nom du secteur (questionnaire)"
        />
      </div>
      <div className="flex flex-row justify-end items-center">
        <button
          className={`${
            !selected ? 'opacity-30' : 'opacity-100 hover:bg-blue-700'
          } bg-blue-500 text-white font-bold py-2 px-4 rounded`}
          onClick={() => onSubmit(selected)}
          disabled={!selected}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default FormsStepSectors;
