import { useCallback, useEffect, useState } from 'react';
import config from '../../config';
import axios from 'axios';
import CatalogItem from './Item';
import Loading from 'components/Loading';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

const Catalog = () => {
  const [projects, setProjects] = useState<any>([]);
  const [markers, setMarkers] = useState<any>([]);

  const axiosCarbonoApi = axios.create({ baseURL: config.CARBONO_API });

  useEffect(() => {
    if (projects.length === 0) {
      axiosCarbonoApi
        .get('/api/projects')
        .then(function (response) {
          setProjects(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [projects]);

  useEffect(() => {
    if (projects) {
      projects.forEach((project: any, index: number) => {
        axios
          .get(
            `https://api.positionstack.com/v1/forward?access_key=b6a18e2fe84b24e34dd4229df12d3bc1&query=${project.country}`
          )
          .then((res: any) => {
            if (!res.data.data[0] || !res.data.data[0]?.longitude || !res.data.data[0]?.latitude)
              return;
            setMarkers((markers: any) => [
              ...markers,
              {
                id: index,
                coordinates: [res.data.data[0].longitude, res.data.data[0].latitude],
                name: res.data.data[0].name,
                projectId: project.projectId
              }
            ]);
          });
      });
    }
  }, [projects]);

  return (
    <>
      <div className=" bg-white mb-20">
        <div className="flex flex-row justify-between items-center mx-auto px-20 pt-10">
          <h1 className="text-3xl font-normal text-black">Catalogue</h1>
        </div>
      </div>
      {projects.length === 0 ? (
        <Loading spinner className="animate-spin text-green-500 mx-auto h-20 w-20" />
      ) : (
        <>
          <div className="-mt-40">
            <ComposableMap>
              <Geographies geography="map.json">
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography key={geo.rsmKey} geography={geo} fill="#EAEAEC" stroke="#D6D6DA" />
                  ))
                }
              </Geographies>
              {markers.map((marker: any) => {
                return (
                  <Marker key={marker.id} coordinates={marker.coordinates}>
                    <a href={`#${marker.projectId}`}>
                      <circle
                        r={5}
                        fill="#49DE80"
                        stroke="#fff"
                        strokeWidth={2}
                        className="animate-pulse"
                      />
                    </a>
                  </Marker>
                );
              })}
            </ComposableMap>
          </div>
          <div className="w-full px-4 sm:px-6 lg:px-8 mb-20">
            <div className="max-w-6xl mx-auto">
              <ul
                role="list"
                className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
              >
                {projects.map((project: any, index: number) => (
                  <CatalogItem key={index} project={project} />
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Catalog;
