import { ApolloClient, useMutation } from '@apollo/client';
import Select from 'components/Select';
import { Form } from 'queryTypes/form';
import { useCallback, useMemo, useState } from 'react';
import SheetCreator from 'components/SheetCreator';
import { useCreateQuestion } from 'services/api/question/queries';
import { Question } from 'queryTypes/question';
import { RocketLaunchIcon } from '@heroicons/react/24/outline';
import { Type } from 'enums/type';

type Props = {
  refetch: () => void;
  form?: Form;
  client?: ApolloClient<any>;
  setNbQuestions?: (value: number) => void;
  nbQuestions?: number;
  question?: Question;
  onSubmit?: (
    question: Question,
    text: string,
    helpMessage: string,
    estimatedTimeToAnswer: number,
    answerType: Type.INTEGER | Type.STRING | Type.BOOLEAN | Type.MULTIPLE_CHOICES | Type.SHEET,
    sheetTemplate: string,
    sheetFormula: string
  ) => void;
};

const FormsStepQuestionsNewQuestion = (props: Props) => {
  const { form, client, refetch, question, setNbQuestions, nbQuestions, onSubmit } = props;
  const [text, setText] = useState<string>(question?.text || '');
  const [helpMessage, setHelpMessage] = useState<string>(question?.helpMessage || '');
  const [answerType, setAnswerType] = useState<
    Type.INTEGER | Type.STRING | Type.BOOLEAN | Type.MULTIPLE_CHOICES | Type.SHEET
  >(question?.answerType || Type.STRING);
  const [estimatedTimeToAnswer, setEstimatedTimeToAnswer] = useState<number>(
    question?.estimatedTimeToAnswer || 30
  );
  const [sheetTemplate, setSheetTemplate] = useState<string>(question?.sheetTemplate || '');
  const [sheetFormula, setSheetFormula] = useState<string>(question?.sheetFormula || '');

  const handleSheetJson = useCallback((sheetJson: string) => {
    setSheetTemplate(sheetJson);
  }, []);

  const [createQuestion, { loading: isLoadingCreateQuestion }] = useMutation(useCreateQuestion);

  const handleCreateQuestion = useCallback(() => {
    if (isLoadingCreateQuestion) return;
    if (!setNbQuestions || !client || !form) return;
    createQuestion({
      variables: {
        question: {
          text,
          helpMessage,
          answerType,
          formId: form.id,
          orderIndex: nbQuestions ? (nbQuestions <= 0 ? 1 : nbQuestions + 1) : 1,
          estimatedTimeToAnswer,
          sheetTemplate,
          sheetFormula
        }
      },
      onCompleted: (data) => {
        if (data && !data.createQuestion.errors) {
          setNbQuestions(nbQuestions ? (nbQuestions <= 0 ? 1 : nbQuestions + 1) : 1);
          setText('');
          setHelpMessage('');
          setAnswerType(Type.STRING);
          setEstimatedTimeToAnswer(30);
          setSheetTemplate('');
          setSheetFormula('');
          refetch();
          client.restore('forms');
        }
      }
    });
  }, [
    nbQuestions,
    question,
    answerType,
    form?.id,
    isLoadingCreateQuestion,
    text,
    helpMessage,
    sheetTemplate,
    sheetFormula
  ]);

  const answerTypeChoices = useMemo(() => {
    return [
      {
        label: 'Texte libre',
        value: Type.STRING
      },
      {
        label: 'Nombre libre',
        value: Type.INTEGER
      },
      {
        label: 'Oui / Non',
        value: Type.BOOLEAN
      },
      {
        label: 'Choix multiples',
        value: Type.MULTIPLE_CHOICES
      },
      {
        label: 'Tableau',
        value: Type.SHEET
      }
    ];
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col space-y-5 py-10">
          <div className="flex justify-start items-center space-x-4">
            <div className="w-full">
              <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900">
                Intitulé de la question
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="text"
                  onChange={(e) => setText(e.target.value)}
                  value={text}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="w-50">
              <label
                htmlFor="estimatedTimeToAnswer"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Temps estimé en secondes
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="estimatedTimeToAnswer"
                  id="estimatedTimeToAnswer"
                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="360"
                  aria-describedby="estimatedTimeToAnswer-seconds"
                  value={estimatedTimeToAnswer}
                  onChange={(e) => setEstimatedTimeToAnswer(parseInt(e.target.value))}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-gray-500 sm:text-sm" id="estimatedTimeToAnswer-seconds">
                    secondes
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="helpMessage"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Description de la question (Message d&apos;aide)
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <textarea
                name="helpMessage"
                onChange={(e) => setHelpMessage(e.target.value)}
                value={helpMessage}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          {text && (
            <div className="flex flex-col space-y-5">
              <div className="flex flex-row justify-start items-center space-x-5">
                <div>
                  <p>Quel est le type de réponse attendu ?</p>
                </div>
                <div className="w-44">
                  <Select
                    selected={answerType}
                    onChange={(value: any) => setAnswerType(value)}
                    options={answerTypeChoices}
                    maxHeight="max-h-[8rem]"
                  />
                </div>
              </div>
              {answerType === 'sheet' && (
                <>
                  <div>
                    <p>
                      Vous avez choisi le type de réponse &quot;Tableau&quot;. Vous pouvez
                      maintenant créer les colonnes de votre tableau.
                    </p>
                    <SheetCreator onSheetJson={handleSheetJson} sheetTemplate={sheetTemplate} />
                  </div>
                  {sheetTemplate && (
                    <div>
                      <input
                        type="text"
                        name="text"
                        onChange={(e) => setSheetFormula(e.target.value)}
                        value={sheetFormula}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Formule de calcul"
                      />
                    </div>
                  )}
                  {sheetTemplate && !question && (
                    <div className="flex justify-end items-center">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handleCreateQuestion}
                      >
                        Créer la question
                      </button>
                    </div>
                  )}
                  {sheetTemplate && question && (
                    <div className="flex justify-end items-center">
                      <button
                        type="submit"
                        className="inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() =>
                          onSubmit &&
                          onSubmit(
                            question,
                            text,
                            helpMessage,
                            estimatedTimeToAnswer,
                            answerType,
                            sheetTemplate,
                            sheetFormula
                          )
                        }
                      >
                        <RocketLaunchIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                        Enregistrer
                      </button>
                    </div>
                  )}
                </>
              )}
              {answerType && answerType !== 'sheet' && !question && (
                <div className="flex justify-end items-center">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleCreateQuestion}
                  >
                    Créer la question
                  </button>
                </div>
              )}
              {answerType && answerType !== 'sheet' && question && (
                <div className="flex justify-end items-center">
                  <button
                    type="submit"
                    className="inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() =>
                      onSubmit &&
                      onSubmit(
                        question,
                        text,
                        helpMessage,
                        estimatedTimeToAnswer,
                        answerType,
                        sheetTemplate,
                        sheetFormula
                      )
                    }
                  >
                    <RocketLaunchIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                    Enregistrer
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FormsStepQuestionsNewQuestion;
