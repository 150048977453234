import { useMutation } from '@apollo/client';
import { PlusIcon } from '@heroicons/react/24/outline';
import Loading from 'components/Loading';
import { Collaborator } from 'queryTypes/collaborator';
import { FilterCollaborator } from 'queryTypes/collaborator/collaborator.dto';
import { useCallback, useState } from 'react';
import {
  useCreateCollaborator,
  useDestroyCollaborator,
  useUpdateCollaborator
} from 'services/api/collaborator/queries';
import Pagination from 'components/Pagination';
import collaboratorService from 'services/api/collaborator/services';
import CollaboratorsItem from './Item';
import { useSearchParams } from 'react-router-dom';
import organizationStore from 'stores/Organization';
import ModalAddCollaborator from './ModalAddCollaborator';

const Collaborators = () => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const [queryParameters] = useSearchParams();
  const [pageSizeValue, setPageSizeValue] = useState<number>(10);
  const [filter, setFilter] = useState<FilterCollaborator>({
    page: 1,
    per_page: pageSizeValue,
    orderByField: 'updatedAt',
    orderByDirection: 'DESC',
    name: queryParameters.get('search') ?? ''
  });

  // Query collaborators
  const {
    data: collaborators,
    refetch,
    error,
    loading: isLoading
  } = collaboratorService.getAllPaginated(filter);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [createCollaborator, { loading: isLoadingCreateCollaborator }] =
    useMutation(useCreateCollaborator);
  const [updateCollaborator, { loading: isLoadingUpdateCollaborator }] =
    useMutation(useUpdateCollaborator);
  const [destroyCollaborator, { loading: isLoadingDestroyCollaborator }] =
    useMutation(useDestroyCollaborator);

  const handleSubmitUpdateCollaborator = useCallback(
    async (id: string, name: string, role: string, email: string) => {
      if (isLoadingUpdateCollaborator) return;
      updateCollaborator({
        variables: {
          collaboratorId: id,
          collaborator: {
            name,
            role,
            email
          }
        },
        onCompleted: (data) => {
          if (data && !data.updateCollaborator.errors) {
            refetch();
          }
        }
      });
    },
    [isLoadingUpdateCollaborator]
  );

  const handleDestroyCollaborator = useCallback((collaborator: Collaborator) => {
    if (isLoadingDestroyCollaborator) return;
    destroyCollaborator({
      variables: {
        collaboratorId: collaborator.id
      },
      onCompleted: (data: { destroyCollaborator: { errors: any } }) => {
        if (data && !data.destroyCollaborator.errors) {
          refetch();
        }
      }
    });
  }, []);

  const handleSubmitCreateCollaborator = useCallback(
    async (customer: string, name: string, role: string) => {
      if (!currentOrganization || isLoadingCreateCollaborator) return;
      createCollaborator({
        variables: {
          collaborator: {
            customerIds: [customer],
            name,
            role
          }
        },
        onCompleted: (data) => {
          if (data && !data.createCollaborator.errors) {
            setIsModalOpen(false);
            refetch();
          }
        }
      });
    },
    [isLoadingCreateCollaborator]
  );

  const setPageSize = useCallback((pageSize: number) => {
    setPageSizeValue(pageSize);
    setFilter((prevFilter) => ({ ...prevFilter, per_page: pageSize }));
  }, []);

  const setPage = useCallback((page: number) => {
    setFilter((prevFilter) => ({ ...prevFilter, page: page }));
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {isLoading && (
        <div className="h-5">
          <Loading />
        </div>
      )}
      <>
        <div className=" bg-white mb-20">
          <div className="flex flex-row justify-between items-center mx-auto px-20 pt-10">
            <h1 className="text-3xl font-normal text-black">Collaborateurs</h1>
          </div>
          <div className="w-full mt-20 px-4 sm:px-6 lg:px-8">
            <div className="max-w-6xl mx-auto">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <p className="mt-2 text-sm text-gray-700">
                    Liste des collaborateurs{' '}
                    <strong>({collaborators?.paginatedCollaborators?.totalCount})</strong>
                  </p>
                </div>
                <div className="w-56 mt-4 sm:mt-0 sm:flex-none">
                  <input
                    type="text"
                    name="name"
                    onChange={(e) =>
                      setFilter((prevFilter) => ({ ...prevFilter, name: e.target.value }))
                    }
                    value={filter.name}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Indiquez un nom de client"
                  />
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="flex flex-row justify-between items-center space-x-2 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <div>
                      <PlusIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                    </div>
                    <div>Nouveau</div>
                  </button>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                          >
                            Client
                          </th>
                          <th
                            scope="col"
                            className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                          >
                            Nom
                          </th>
                          <th
                            scope="col"
                            className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                          >
                            Role
                          </th>
                          <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                            <span className="sr-only">Modifier</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {collaborators?.paginatedCollaborators.data.map(
                          (collaborator: Collaborator, index: number) => (
                            <CollaboratorsItem
                              key={index}
                              collaborator={collaborator}
                              refetch={refetch}
                              onUpdate={handleSubmitUpdateCollaborator}
                              onDestroy={handleDestroyCollaborator}
                            />
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalAddCollaborator
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleSubmitCreateCollaborator}
        />
        {collaborators?.paginatedCollaborators.totalCount > 0 && (
          <Pagination
            title="collaborateurs(s)"
            page={collaborators?.paginatedCollaborators.page}
            totalCount={collaborators?.paginatedCollaborators.totalCount}
            pageSize={pageSizeValue}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        )}
      </>
    </>
  );
};

export default Collaborators;
