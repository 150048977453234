import { ApolloClient, useMutation } from '@apollo/client';
import Loading from 'components/Loading';
import Select from 'components/Select';
import { Customer } from 'queryTypes/customer';
import { Form } from 'queryTypes/form';
import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUpdateCustomer } from 'services/api/customer/queries';
import customerService from 'services/api/customer/services';
import organizationStore from 'stores/Organization';

type Props = {
  form: Form;
  client: ApolloClient<any>;
};

const FormsStepGenerateCustomer = (props: Props) => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const { form, client } = props;
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  // Query customers
  const {
    data: customers,
    error,
    refetch,
    loading: isLoading
  } = customerService.getAll({
    workspaceId: currentOrganization?.id
  });

  const [updateCustomer, { loading }] = useMutation(useUpdateCustomer);

  const handleGenerate = useCallback((customerId: string) => {
    if (loading) return;
    updateCustomer({
      variables: {
        customerId: customerId,
        customer: {
          formIds: [form.id]
        }
      },
      onCompleted: (data) => {
        if (data && !data.updateCustomer.errors) {
          setUrl(`https://app.carbono.eco/forms/${data.updateCustomer.customer.id}`);
          refetch();
          client.restore('forms');
        }
      }
    });
  }, []);

  if (error) {
    return <div>Erreur: {error.message}</div>;
  }

  return (
    <>
      {isLoading && <Loading />}
      <div className="max-w-6xl mx-auto">
        <div className="mb-10">
          {!url && !isLoading && customers && (
            <Select
              selected={selected}
              options={[
                {
                  label: 'Sélectionnez un client',
                  value: ''
                },
                ...customers?.customers?.map((customer: Customer) => ({
                  value: customer.id,
                  label: customer.name
                }))
              ]}
              onChange={(value) => setSelected(value)}
            />
          )}
          {selected && url && (
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
                <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  URL Générée pour le client &quot;
                  <code>
                    {
                      customers?.customers?.find((customer: Customer) => customer.id === selected)
                        ?.name
                    }
                  </code>
                  &quot;
                </h2>
                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                  Vous pouvez dès maintenant partager cette URL à votre client pour qu&apos;il
                  puisse répondre au formulaire.
                  <br />
                  <br />
                  <code>{url}</code>
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                    }}
                    className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Copier l&apos;URL
                  </button>
                  <Link to="/forms" className="text-sm font-semibold leading-6 text-white">
                    Revenir aux questionnaires <span aria-hidden="true">→</span>
                  </Link>
                </div>
                <svg
                  viewBox="0 0 1024 1024"
                  className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                  aria-hidden="true"
                >
                  <circle
                    cx={512}
                    cy={512}
                    r={512}
                    fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                    fillOpacity="0.7"
                  />
                  <defs>
                    <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                      <stop stopColor="#7775D6" />
                      <stop offset={1} stopColor="#E935C1" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
            </div>
          )}
        </div>
        {!url && (
          <div className="flex flex-row justify-between items-center">
            <button
              className={`justify-start hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded`}
              onClick={() => navigate('/forms')}
            >
              Non merci
            </button>
            <button
              className={`justify-end ${
                !selected ? 'opacity-30' : 'opacity-100 hover:bg-blue-700'
              } bg-blue-500 text-white font-bold py-2 px-4 rounded`}
              onClick={() => handleGenerate(selected)}
              disabled={!selected}
            >
              Générer
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FormsStepGenerateCustomer;
