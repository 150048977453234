import ModalForm from 'components/ModalForm';
import { Collaborator } from 'queryTypes/collaborator';
import { useEffect, useState } from 'react';

type Props = {
  collaborator: Collaborator;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (id: string, name: string, role: string, email: string) => void;
};
const ModalEditCollaborator = (props: Props) => {
  const { collaborator, isOpen, onClose, onSubmit } = props;
  const [name, setName] = useState<string>(collaborator.name);
  const [role, setRole] = useState<string>(collaborator.role);
  const [email, setEmail] = useState<string>(collaborator.email);

  useEffect(() => {
    setName(collaborator.name);
    setRole(collaborator.role);
    setEmail(collaborator.email);
  }, [collaborator]);

  return (
    <ModalForm
      title={`Modifiez le collaborateur ${collaborator.name}`}
      ctaTitle="Enregistrer"
      size="md"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit(collaborator.id, name, role, email)}
      onLoading={name === '' || role === '' || email === ''}
      withCloseButton
    >
      <div className="flex flex-col space-y-5">
        <div>
          <div className="sm:col-span-4">
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
              Nom du collaborateur
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Champagne Lanson"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="sm:col-span-4">
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
              Email du collaborateur
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="john.doe@gmail.com"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start items-center space-x-5">
          <div className="sm:col-span-4">
            <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
              Role
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="role"
                onChange={(e) => setRole(e.target.value)}
                value={role}
                className="block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="john.doe@example.com"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalEditCollaborator;
