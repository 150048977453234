import { gql } from '@apollo/client';
import { FilterStat } from 'queryTypes/stat';

export const useCreateStat = gql`
  mutation createStat($stat: StatInputType!) {
    createStat(attributes: $stat) {
      stat {
        id
      }
      errors
    }
  }
`;

export const useUpdateStat = gql`
  mutation updateStat($statId: StatId!, $stat: StatInputType!) {
    updateStat(id: $statId, attributes: $stat) {
      stat {
        id
      }
      errors
    }
  }
`;

export const useGetStat = (options: FilterStat) => gql`
  query getStat {
    stat(id: "${options.id}") {
      id
      camembert
      bar
      equivalent
      intensity
      year
      form {
        id
      }
    }
  }
`;
