import { gql } from '@apollo/client';
import { FilterCollaborator } from 'queryTypes/collaborator';

export const useGetAllCollaboratorsPaginated = (options?: FilterCollaborator) => gql`
  query getCollaboratorsPaginated {
    paginatedCollaborators(page: ${options?.page}, perPage: ${options?.per_page}, filter: "${
      options?.name ? `customers.name % '${options?.name}'` : ``
    }", ${
      options?.orderByField
        ? `, orderBy: "${options?.orderByField} ${options?.orderByDirection}"`
        : ``
    }) {
    page
    perPage
    totalCount
    data {
      id
      name
      role
      email
      answers {
        id
        question {
          id
          text
        }
        value
        sheet
        csvUrl
      }
      customers {
        id
        name
        email
        forms {
          id
          questions {
            id
            orderIndex
            text
            collaborators {
              id
              name
              role
              email
            }
          }
        }
      }
    }
  }
}
`;

export const useGetAllCollaborators = (options?: FilterCollaborator) => gql`
  query Collaborators {
    collaborators(filter: "${
      options?.customerId ? `customer_id == '${options?.customerId}'` : ``
    }") {
    id
    name
    role
    email
    answers {
      id
      question {
        id
        text
      }
      value
      sheet
      csvUrl
    }
    customers {
      id
      name
      email
      forms {
        id
        questions {
          id
          orderIndex
          text
          collaborators {
            id
            name
            role
            email
          }
        }
      }
    }
  }
}
`;

export const useGetCollaborator = (options?: FilterCollaborator) => gql`
  query getCollaborator {
    collaborator(id : "${options?.customerId}") {
      id
      name
      role
      email
      answers {
        id
        question {
          id
          text
        }
        value
        sheet
        csvUrl
      }
      customers {
        id
        name
        email
        forms {
          id
          questions {
            id
            orderIndex
            text
            collaborators {
              id
              name
              role
              email
            }
          }
        }
      }
    }
  }
`;

export const useCreateCollaborator = gql`
  mutation createCollaborator($collaborator: CollaboratorInputType!) {
    createCollaborator(attributes: $collaborator) {
      collaborator {
        id
      }
      errors
    }
  }
`;

export const useUpdateCollaborator = gql`
  mutation updateCollaborator($collaboratorId: String!, $collaborator: CollaboratorInputType!) {
    updateCollaborator(id: $collaboratorId, attributes: $collaborator) {
      collaborator {
        id
      }
      errors
    }
  }
`;

export const useDestroyCollaborator = gql`
  mutation destroyCollaborator($collaboratorId: String!) {
    destroyCollaborator(id: $collaboratorId) {
      collaborator {
        id
      }
      errors
    }
  }
`;
