import { gql } from '@apollo/client';
import { FilterExcelMapping } from 'queryTypes/excelMapping';

export const useGetExcelMapping = (options?: FilterExcelMapping) => gql`
  query getExcelMapping {
    excelMapping(id : "${options?.id}") {
      id
      excelBaseColumn
      excelBaseLine
      excelTab
      insert
      orderIndex
      value
    }
  }
`;

export const useCreateExcelMapping = gql`
  mutation createExcelMapping($excelMapping: ExcelMappingInputType!) {
    createExcelMapping(attributes: $excelMapping) {
      excelMapping {
        id
        excelBaseColumn
        excelBaseLine
        excelTab
        insert
        orderIndex
        value
      }
      errors
    }
  }
`;

export const useUpdateExcelMapping = gql`
  mutation updateExcelMapping(
    $excelMappingId: ExcelMappingId!
    $excelMapping: ExcelMappingInputType!
  ) {
    updateExcelMapping(id: $excelMappingId, attributes: $excelMapping) {
      excelMapping {
        id
      }
      errors
    }
  }
`;

export const useDestroyExcelMapping = gql`
  mutation destroyExcelMapping($excelMappingId: ExcelMappingId!) {
    destroyExcelMapping(id: $excelMappingId) {
      excelMapping {
        id
      }
      errors
    }
  }
`;
