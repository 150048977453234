import {
  ExclamationTriangleIcon,
  MinusIcon,
  PlusIcon,
  XMarkIcon,
  Cog6ToothIcon,
  PencilIcon,
  DocumentDuplicateIcon
} from '@heroicons/react/24/outline';
import { Question } from 'queryTypes/question';
import { useCallback, useState } from 'react';
import ModalAddPossibleAnswer from './ModalAddPossibleAnswer';
import ModalMapping from '../../../../../components/ModalMapping';
import ModalEdit from './ModalEdit';
import ModalDelete from 'components/ModalDelete';
import { Type } from 'enums/type';
import Loading from 'components/Loading';
import ModalDuplicateQuestion from './ModalDuplicateQuestion';

type Props = {
  question: Question;
  answerTypeChoices: { value: string; label: string }[];
  onDestroy: (question: Question) => void;
  onChangePosition: (question: Question, position: number) => void;
  onUpdate: (
    question: Question,
    text: string,
    helpMessage: string,
    estimatedTimeToAnswer: number,
    answerType: Type.INTEGER | Type.STRING | Type.BOOLEAN | Type.MULTIPLE_CHOICES | Type.SHEET,
    sheetTemplate: string,
    sheetFormula: string
  ) => void;
  onDuplicate: (question: Question, text: string) => void;
  refetch: () => void;
  isLoading: boolean;
};

const FormsStepQuestionsItem = (props: Props) => {
  const {
    question,
    answerTypeChoices,
    onDestroy,
    onChangePosition,
    refetch,
    onUpdate,
    onDuplicate,
    isLoading
  } = props;
  const [isModalOpenPossiblesValue, setIsModalOpenPossiblesValue] = useState<boolean>(false);
  const [isModalOpenMapping, setIsModalOpenMapping] = useState<boolean>(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState<boolean>(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState<boolean>(false);
  const [isModalOpenDuplicate, setIsModalOpenDuplicate] = useState<boolean>(false);

  const handleUpdateQuestion = useCallback(
    (
      question: Question,
      text: string,
      helpMessage: string,
      estimatedTimeToAnswer: number,
      answerType: Type.INTEGER | Type.STRING | Type.BOOLEAN | Type.MULTIPLE_CHOICES | Type.SHEET,
      sheetTemplate: string,
      sheetFormula: string
    ) => {
      onUpdate(
        question,
        text,
        helpMessage,
        estimatedTimeToAnswer,
        answerType,
        sheetTemplate,
        sheetFormula
      );
      setIsModalOpenEdit(false);
    },
    []
  );

  const handleDuplicateForm = useCallback((question: Question, text: string) => {
    onDuplicate(question, text);
    setIsModalOpenDuplicate(false);
  }, []);

  return (
    <>
      <tr className="hidden">
        <td>
          <ModalAddPossibleAnswer
            isOpen={isModalOpenPossiblesValue}
            onClose={() => setIsModalOpenPossiblesValue(false)}
            onSubmit={() => setIsModalOpenPossiblesValue(false)}
            question={question}
            refetch={refetch}
          />
          <ModalMapping
            isOpen={isModalOpenMapping}
            onClose={() => setIsModalOpenMapping(false)}
            onSubmit={() => setIsModalOpenMapping(false)}
            object={question}
            refetch={refetch}
            nbExcelMapping={question.excelMappings.length}
          />
          <ModalEdit
            isOpen={isModalOpenEdit}
            onClose={() => setIsModalOpenEdit(false)}
            onSubmit={handleUpdateQuestion}
            refetch={refetch}
            question={question}
          />
          <ModalDelete
            title="Supprimer une question"
            isOpen={isModalOpenDelete}
            onClose={() => setIsModalOpenDelete(false)}
            onSubmit={() => {
              setIsModalOpenDelete(false);
              onDestroy(question);
            }}
          >
            la question {question.text}
          </ModalDelete>
          <ModalDuplicateQuestion
            isOpen={isModalOpenDuplicate}
            onClose={() => setIsModalOpenDuplicate(false)}
            onSubmit={handleDuplicateForm}
            question={question}
          />
        </td>
      </tr>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <div>
            {!isLoading ? (
              <input
                className="block w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                type="number"
                onChange={(e) => onChangePosition(question, parseInt(e.target.value))}
                value={question.orderIndex}
                min={1}
              />
            ) : (
              <Loading spinner />
            )}
          </div>
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          {question.text}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {answerTypeChoices.find((type) => type.value === question.answerType)?.label}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {question.answerType === 'multiple_choices' ? (
            <div className="flex flex-row justify-start space-x-2 items-center">
              {question.possibleAnswers.length === 0 && (
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-red-600 animate-pulse"
                  aria-hidden="true"
                />
              )}
              <p>{question.possibleAnswers.length} valeur(s)</p>
            </div>
          ) : (
            <MinusIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="flex flex-row justify-start space-x-2 items-center">
            {question.excelMappings.length === 0 && (
              <ExclamationTriangleIcon
                className="h-5 w-5 text-red-600 animate-pulse"
                aria-hidden="true"
              />
            )}
            <p>{question.excelMappings.length} règle(s) de mapping</p>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="flex flex-row justify-between space-x-5 items-center">
            <div>
              {question.answerType === 'multiple_choices' && (
                <button
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-green-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  onClick={() => setIsModalOpenPossiblesValue(true)}
                >
                  <PlusIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                  Ajouter des réponses
                </button>
              )}
            </div>
            <div className="flex flex-row justify-between items-center space-x-3">
              <button
                className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => setIsModalOpenEdit(true)}
              >
                <PencilIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                Modifier
              </button>
              <button
                onClick={() => setIsModalOpenDuplicate(true)}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-lime-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
              >
                <DocumentDuplicateIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                Dupliquer
              </button>
              <button
                className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => setIsModalOpenMapping(true)}
              >
                <Cog6ToothIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                Mapping
              </button>
              <button
                onClick={() => setIsModalOpenDelete(true)}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                <XMarkIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                Supprimer
              </button>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default FormsStepQuestionsItem;
