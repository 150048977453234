import { gql } from '@apollo/client';
import { FilterCustomer } from 'queryTypes/customer';

export const useGetAllCustomersPaginated = (options?: FilterCustomer) => gql`
  query getCustomersPaginated {
    paginatedCustomers(page: ${options?.page}, perPage: ${options?.per_page}, filter: "${
      options?.workspaceId ? `workspace_id == '${options?.workspaceId}'` : ``
    }${options?.name ? ` && name % '${options?.name}'` : ``}", ${
      options?.orderByField
        ? `, orderBy: "${options?.orderByField} ${options?.orderByDirection}"`
        : ``
    }) {
    page
    perPage
    totalCount
    data {
      id
      name
      email
      phone
      address1
      address2
      zipCode
      city
      country
      avatarUrl
      forms {
        id
        sector
        stats {
          id
          camembert
          bar
          equivalent
          intensity
          year
        }
      }
      answers {
        id
        question {
          id
          text
        }
        value
        sheet
        csvUrl
        updatedAt
        collaborator {
          id
          name
          role
        }
      }
      collaborators {
        id
        name
        role
      }
    }
  }
  }
`;

export const useGetAllCustomers = (options?: FilterCustomer) => gql`
  query Customers {
    customers(filter: "${options?.workspaceId ? `workspace_id == '${options?.workspaceId}'` : ``}${
      options?.customerId ? ` && id == '${options?.customerId}'` : ``
    }") {
      id
      name
      email
      phone
      address1
      address2
      zipCode
      city
      country
      avatarUrl
      forms {
        id
        sector
        stats {
          id
          camembert
          bar
          equivalent
          intensity
          year
        }
      }
      collaborators {
        id
        name
        role
      }
    }
  }
`;

export const useGetCustomer = (options?: FilterCustomer) => gql`
  query getCustomer {
    customer(id : "${options?.customerId}") {
      id
      name
      email
      phone
      address1
      address2
      zipCode
      city
      country
      avatarUrl
      forms {
        id
        sector
        stats {
          id
          camembert
          bar
          equivalent
          intensity
          year
        }
      }
      collaborators {
        id
        name
        role
      }
    }
  }
`;

export const useCreateCustomer = gql`
  mutation createCustomer($customer: CustomerInputType!) {
    createCustomer(attributes: $customer) {
      customer {
        id
      }
      errors
    }
  }
`;

export const useUpdateCustomer = gql`
  mutation updateCustomer($customerId: CustomerId!, $customer: CustomerInputType!) {
    updateCustomer(id: $customerId, attributes: $customer) {
      customer {
        id
      }
      errors
    }
  }
`;

export const useDestroyCustomer = gql`
  mutation destroyCustomer($customerId: CustomerId!) {
    destroyCustomer(id: $customerId) {
      customer {
        id
      }
      errors
    }
  }
`;
