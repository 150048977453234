import { useMutation } from '@apollo/client';
import ModalForm from 'components/ModalForm';
import Select from 'components/Select';
import { Tabs } from 'utils';
import { ExcelMapping } from 'queryTypes/excelMapping';
import { Question } from 'queryTypes/question';
import { useCallback, useState } from 'react';
import { useCreateExcelMapping, useUpdateExcelMapping } from 'services/api/excelMapping/queries';
import { Switch } from '@headlessui/react';
import ModalMappingItem from './Item';
import { Form } from 'queryTypes/form';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  object: Question | Form;
  refetch: () => void;
  nbExcelMapping: number;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const ModalMapping = (props: Props) => {
  const { isOpen, onClose, onSubmit, object, refetch, nbExcelMapping } = props;

  const [excelTab, setExcelTab] = useState<string>('');
  const [excelBaseColumn, setExcelBaseColumn] = useState<string>('');
  const [excelBaseLine, setExcelBaseLine] = useState<number>(0);
  const [insert, setInsert] = useState<boolean>(false);
  const [orderIndex, setOrderIndex] = useState<number>(0);
  const [value, setValue] = useState<string>('');

  const [createExcelMapping, { loading: isLoadingCreateExcelMapping }] =
    useMutation(useCreateExcelMapping);
  const [changePositionExcelMapping, { loading: isLoadingUpdateExcelMapping }] =
    useMutation(useUpdateExcelMapping);

  const handleUpdateExcelMapping = useCallback(
    (excelMapping: ExcelMapping, position: number) => {
      if (isLoadingUpdateExcelMapping) return;
      changePositionExcelMapping({
        variables: {
          excelMappingId: excelMapping.id,
          excelMapping: {
            orderIndex: position
          }
        },
        onCompleted: (data) => {
          if (data && !data.updateExcelMapping.errors) {
            refetch();
          }
        }
      });
    },
    [isLoadingUpdateExcelMapping]
  );

  const handleCreateExcelMapping = useCallback(() => {
    if (isLoadingCreateExcelMapping) return;
    if (object.__typename === 'Question') {
      createExcelMapping({
        variables: {
          excelMapping: {
            questionId: object.id,
            excelTab,
            excelBaseColumn,
            excelBaseLine,
            insert,
            orderIndex: nbExcelMapping + 1,
            value
          }
        },
        onCompleted: (data) => {
          if (data && !data.createExcelMapping.errors) {
            setExcelTab('');
            setExcelBaseColumn('');
            setExcelBaseLine(0);
            setInsert(false);
            setOrderIndex(0);
            setValue('');
            refetch();
          }
        }
      });
    } else {
      createExcelMapping({
        variables: {
          excelMapping: {
            formId: object.id,
            excelTab,
            excelBaseColumn,
            excelBaseLine,
            insert,
            orderIndex: nbExcelMapping + 1,
            value
          }
        },
        onCompleted: (data) => {
          if (data && !data.createExcelMapping.errors) {
            setExcelTab('');
            setExcelBaseColumn('');
            setExcelBaseLine(0);
            setInsert(false);
            setOrderIndex(0);
            setValue('');
            refetch();
          }
        }
      });
    }
  }, [
    object,
    excelBaseColumn,
    excelBaseLine,
    excelTab,
    insert,
    orderIndex,
    value,
    isLoadingCreateExcelMapping
  ]);

  return (
    <ModalForm
      title="Ajoutez des règles de mapping"
      ctaTitle="J'ai terminé"
      size="md"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit()}
    >
      <div className="flex flex-col space-y-5">
        <div>
          <Select selected={excelTab} options={Tabs} onChange={(value) => setExcelTab(value)} />
        </div>
        <div className="flex flex-row justify-start items-center space-x-5">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="excelBaseColumn" className="block text-xs font-medium text-gray-900">
              Remplir la colonne
            </label>
            <input
              type="text"
              name="excelBaseColumn"
              onChange={(e) => setExcelBaseColumn(e.target.value)}
              value={excelBaseColumn}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="B"
            />
          </div>
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="excelBaseLine" className="block text-xs font-medium text-gray-900">
              à la ligne
            </label>
            <input
              type="number"
              min={0}
              name="excelBaseLine"
              onChange={(e) => setExcelBaseLine(parseInt(e.target.value))}
              value={excelBaseLine}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="202"
            />
          </div>
          <div>
            <label htmlFor="insert" className="block text-xs font-medium text-gray-900 mb-2">
              En insertion ?
            </label>
            <Switch
              name="insert"
              checked={insert}
              onChange={setInsert}
              className={classNames(
                insert ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
              )}
            >
              <span className="sr-only">En insertion ?</span>
              <span
                aria-hidden="true"
                className={classNames(
                  insert ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          </div>
        </div>
        <div>
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="excelBaseColumn" className="block text-xs font-medium text-gray-900">
              Avec la valeur
            </label>
            <input
              type="text"
              name="value"
              onChange={(e) => setValue(e.target.value)}
              value={value}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="={{answer}} * 100"
            />
            <p className="mt-2 text-sm text-gray-500 cursor-default">
              <code>{'{{answer}}'}</code> disponible, ainsi que les opérateurs mathématiques (
              <code>+</code>, <code>-</code>, <code>*</code>, <code>/</code>)
              <br />
              Exemple : <code>{'={{answer}} * 100'}</code>
            </p>
          </div>
        </div>
        {excelTab && excelBaseColumn && excelBaseLine && value && (
          <div className="flex justify-end items-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleCreateExcelMapping}
            >
              Créer la règle
            </button>
          </div>
        )}
        <div className="mt-8 mb-10 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                    >
                      Position
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Règle
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Supprimer</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {object?.excelMappings?.map((excelMapping: ExcelMapping, index: number) => (
                    <ModalMappingItem
                      key={index}
                      excelMapping={excelMapping}
                      nbExcelMapping={nbExcelMapping}
                      onChangePosition={handleUpdateExcelMapping}
                      refetch={refetch}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalMapping;
