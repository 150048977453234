import { TabsEnum } from 'enums/tabs';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import config from 'config';

export const handleUpload = async (selectedFile: File) => {
  const client = new S3Client({
    region: 'eu-north-1',
    credentials: {
      accessKeyId: config.AWS_ACCESS_KEY_ID as string,
      secretAccessKey: config.AWS_SECRET_ACCESS_KEY as string
    }
  });

  const command = new PutObjectCommand({
    Bucket: 'footprint-media',
    Key: selectedFile.name,
    Body: selectedFile
  });

  try {
    await client.send(command);

    const url = `https://footprint-media.s3.eu-north-1.amazonaws.com/${selectedFile.name}`;
    return url;
  } catch (err) {
    console.log(err);
    return '';
  }
};

export const TabsModels = [
  { label: TabsEnum.DESCRIPTIF, value: TabsEnum.DESCRIPTIF },
  { label: TabsEnum.ENERGIE_1, value: TabsEnum.ENERGIE_1 },
  { label: TabsEnum.ENERGIE_2, value: TabsEnum.ENERGIE_2 },
  { label: TabsEnum.HORS_ENERGIE_1, value: TabsEnum.HORS_ENERGIE_1 },
  { label: TabsEnum.HORS_ENERGIE_2, value: TabsEnum.HORS_ENERGIE_2 },
  { label: TabsEnum.INTRANTS_1, value: TabsEnum.INTRANTS_1 },
  { label: TabsEnum.INTRANTS_2, value: TabsEnum.INTRANTS_2 },
  { label: TabsEnum.FUTURS_EMBALLAGES, value: TabsEnum.FUTURS_EMBALLAGES },
  { label: TabsEnum.FRET, value: TabsEnum.FRET },
  { label: TabsEnum.DEPLACEMENTS, value: TabsEnum.DEPLACEMENTS },
  { label: TabsEnum.IMMOBILISATIONS, value: TabsEnum.IMMOBILISATIONS },
  { label: TabsEnum.UTILISATION, value: TabsEnum.UTILISATION },
  { label: TabsEnum.FIN_DE_VIE, value: TabsEnum.FIN_DE_VIE },
  { label: TabsEnum.UTILITAIRES, value: TabsEnum.UTILITAIRES },
  { label: TabsEnum.RECAP_COE2E, value: TabsEnum.RECAP_COE2E },
  { label: TabsEnum.RATIOS, value: TabsEnum.RATIOS },
  { label: TabsEnum.BILAN_GES, value: TabsEnum.BILAN_GES },
  { label: TabsEnum.ISO_14069, value: TabsEnum.ISO_14069 },
  { label: TabsEnum.GHG_PROTOCOL, value: TabsEnum.GHG_PROTOCOL },
  { label: TabsEnum.CDP_2018, value: TabsEnum.CDP_2018 },
  { label: TabsEnum.GRAPHIQUES, value: TabsEnum.GRAPHIQUES },
  { label: TabsEnum.FE_ENERGIE, value: TabsEnum.FE_ENERGIE },
  { label: TabsEnum.FE_HORS_ENERGIE, value: TabsEnum.FE_HORS_ENERGIE },
  { label: TabsEnum.FE_INTRANTS, value: TabsEnum.FE_INTRANTS },
  { label: TabsEnum.FE_DECHETS, value: TabsEnum.FE_DECHETS },
  { label: TabsEnum.FE_FRETS, value: TabsEnum.FE_FRETS },
  { label: TabsEnum.FE_DEPLACEMENTS, value: TabsEnum.FE_DEPLACEMENTS },
  { label: TabsEnum.FE_IMMOBILISATIONS, value: TabsEnum.FE_IMMOBILISATIONS },
  { label: TabsEnum.EXPORT_POSTES, value: TabsEnum.EXPORT_POSTES },
  { label: TabsEnum.EXPORT_SOUS_POSTES, value: TabsEnum.EXPORT_SOUS_POSTES }
];

export const Tabs = [{ label: 'Sélectionnea une tab du fichier excel', value: '' }, ...TabsModels];

export const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua & Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia & Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D Ivoire',
  'Croatia',
  'Cruise Ship',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Polynesia',
  'French West Indies',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Guadeloupe',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Kyrgyz Republic',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Pierre & Miquelon',
  'Samoa',
  'San Marino',
  'Satellite',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St Kitts & Nevis',
  'St Lucia',
  'St Vincent',
  'St. Lucia',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  "Timor L'Este",
  'Togo',
  'Tonga',
  'Trinidad & Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks & Caicos',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (US)',
  'Yemen',
  'Zambia',
  'Zimbabwe'
];

export const uniqueArray = (objects: any[], uniqueBy: any[], keepFirst = true) => {
  return Array.from(
    objects
      .reduce(
        (
          map: { has: (arg0: any) => any; set: (arg0: any, arg1: any) => any },
          e: { [x: string]: any }
        ) => {
          let key = uniqueBy
            .map((key: string | number) => [e[key], typeof e[key]])
            .flat()
            .join('-');
          if (keepFirst && map.has(key)) return map;
          return map.set(key, e);
        },
        new Map()
      )
      .values()
  );
};
