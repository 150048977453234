import { gql } from '@apollo/client';
import { FilterForm } from 'queryTypes/form/form.dto';

export const useGetAllFormsPaginated = (options?: FilterForm) => gql`
  query getFormsPaginated {
    paginatedForms(page: ${options?.page}, perPage: ${options?.per_page}, filter: "${
      options?.workspaceId ? `workspace_id == '${options?.workspaceId}'` : ``
    }${options?.sector ? ` && sector == '${options?.sector}'` : ``}"${
      options?.orderByField
        ? `, orderBy: "${options?.orderByField} ${options?.orderByDirection}"`
        : ``
    }) {
      page
      perPage
      totalCount
      data {
        id
        sector
        questions {
          id
        }
        customers {
          id
          name
        }
        excelMappings {
          id
          excelBaseColumn
          excelBaseLine
          excelTab
          insert
          orderIndex
          value
        }
      }
    }
  }
`;

export const useGetAllForms = (options?: FilterForm) => gql`
  query getForms {
    forms(filter: "${options?.workspaceId ? `workspace_id == '${options?.workspaceId}'` : ``}${
      options?.sector ? ` && sector == '${options?.sector}'` : ``
    }"${
      options?.orderByField
        ? `, orderBy: "${options?.orderByField} ${options?.orderByDirection}"`
        : ``
    }) {
      id
      sector
      questions {
        id
      }
      customers {
        id
        name
      }
      excelMappings {
        id
        excelBaseColumn
        excelBaseLine
        excelTab
        insert
        orderIndex
        value
      }
    }
  }
`;

export const useGetAllSectors = () => gql`
  query getSectors {
    sectors
  }
`;

export const useCreateForm = gql`
  mutation createForm($form: FormInputType!) {
    createForm(attributes: $form) {
      form {
        id
        sector
      }
      errors
    }
  }
`;

export const useDuplicateForm = gql`
  mutation duplicateForm($formId: FormId!, $sector: String!) {
    duplicateForm(id: $formId, sector: $sector) {
      form {
        id
        sector
      }
      errors
    }
  }
`;

export const useUpdateForm = gql`
  mutation updateForm($formId: FormId!, $form: FormInputType!) {
    updateForm(id: $formId, attributes: $form) {
      form {
        id
      }
      errors
    }
  }
`;

export const useGetForm = (options: FilterForm) => gql`
  query getForm {
    form(id: "${options.id}") {
      id
      sector
      questions {
        id
        text
        helpMessage
        orderIndex
        answerType
        estimatedTimeToAnswer
        sheetFormula
        sheetTemplate
        answers {
          id
          value
          sheet
          csvUrl
          updatedAt
          collaborator {
            id
            name
            role
          }
        }
      }
      excelMappings {
        id
        excelBaseColumn
        excelBaseLine
        excelTab
        insert
        orderIndex
        value
      }
    }
  }
`;

export const useDestroyForm = gql`
  mutation destroyForm($formId: FormId!) {
    destroyForm(id: $formId) {
      form {
        id
      }
      errors
    }
  }
`;
