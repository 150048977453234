import { useCallback, useEffect, useState } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';

type Props = {
  selectedFile: any;
  setSelectedFile: (file: any) => void;
  defaultFile?: string;
};

export default function FileUpload(props: Props) {
  const { selectedFile, setSelectedFile, defaultFile } = props;
  const [preview, setPreview] = useState<string>('');

  const handleFileChange = useCallback((e: any) => {
    setSelectedFile(e.target.files[0]);
  }, []);

  useEffect(() => {
    if (!selectedFile) return;
    // create the preview
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <div className="col-span-full">
      <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
        Image (logo visible sur le questionnaire)
      </label>
      {selectedFile && <img src={preview} alt="preview" className="w-full" />}
      {defaultFile && !selectedFile && <img src={defaultFile} alt="preview" className="w-full" />}
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <div className="text-center">
          <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative mx-auto cursor-pointer rounded-md bg-white font-semibold text-indigo-600 hover:text-indigo-500"
            >
              <span>Upload un fichier</span>
              <input
                onChange={handleFileChange}
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
              />
            </label>
          </div>
          <p className="text-xs leading-5 text-gray-600">Préférez PNG pour la transparence</p>
          <p className="text-xs leading-5 text-gray-600">PNG, JPG maximum 2MB</p>
        </div>
      </div>
    </div>
  );
}
