import { XMarkIcon } from '@heroicons/react/24/outline';

type Props = {
  data: any;
  onDelete: () => void;
  onChangeLabel: (item: any, label: string) => void;
  onChangeValue: (item: any, value: number) => void;
};

const ModalEditStatisticItem = (props: Props) => {
  const { data, onDelete, onChangeLabel, onChangeValue } = props;

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        <input
          type="text"
          onChange={(e) => onChangeLabel(data, e.target.value)}
          value={data.label}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <input
          type="number"
          onChange={(e) => onChangeValue(data, Number(e.target.value))}
          value={data.percent}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </td>
      <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <button
          onClick={onDelete}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          <XMarkIcon className="h-5 w-5 inline-block" aria-hidden="true" />
          Supprimer
        </button>
      </td>
    </tr>
  );
};

export default ModalEditStatisticItem;
