import ModalForm from 'components/ModalForm';
import { Answer } from 'queryTypes/answer';
import { Customer } from 'queryTypes/customer';
import { Key } from 'react';
import { formatDistance, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Question } from 'queryTypes/question';
import formService from 'services/api/forms/services';
import organizationStore from 'stores/Organization';
import Loading from 'components/Loading';
import { Link } from 'react-router-dom';

type Props = {
  customer: Customer;
  isOpen: boolean;
  onClose: () => void;
};

const ModalViewAnswers = (props: Props) => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const { isOpen, onClose, customer } = props;
  // Query form
  const { data: form, loading: isLoading } = formService.getForm({
    workspaceId: currentOrganization.id,
    id: customer.forms[0]?.id
  });
  return (
    <ModalForm
      title={`Aperçu client`}
      ctaTitle="J'ai terminé"
      size="xl"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onClose()}
    >
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <div className="px-4 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Réponse du client {customer.name}
              </h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Les réponses du client {customer.name} sont les suivantes :
              </p>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                {form.form.questions
                  ?.map((question: Question) => question)
                  ?.sort((a: Question, b: Question) => a.orderIndex - b.orderIndex)
                  ?.map((question: Question, index: number) => {
                    const answers = question.answers;
                    if (!answers) return;

                    return answers.map((answer: Answer) => {
                      const sheetAnswer = answer && answer.sheet && JSON.parse(answer?.sheet)[0];
                      return (
                        <div
                          key={index}
                          className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                        >
                          <dt className="text-sm font-medium leading-6 text-indigo-600 hover:underline hover:text-indigo-500">
                            <Link
                              to={`https://app.carbono.eco/forms/${
                                answer.collaborator
                                  ? `collaborators/${answer.collaboratorId}`
                                  : customer.id
                              }/${question.orderIndex}`}
                              target="_blank"
                            >
                              {question.orderIndex}. {question.text}
                            </Link>
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                            {answer.sheet ? (
                              <>
                                {answer.csvUrl && (
                                  <p>
                                    Un fichier a été importé:{' '}
                                    <Link
                                      to={`https://footprint-media.s3.eu-north-1.amazonaws.com/${answer.csvUrl}`}
                                      target="_blank"
                                      className="text-indigo-600 hover:underline hover:text-indigo-500"
                                    >
                                      {answer.csvUrl}
                                    </Link>
                                  </p>
                                )}
                                <table className="min-w-full divide-y divide-gray-900 mb-28 mt-10">
                                  <thead>
                                    <tr>
                                      {sheetAnswer.map(
                                        (
                                          value: { column: string; value: string },
                                          index: Key | null | undefined
                                        ) => (
                                          <th
                                            key={index}
                                            className="px-6 py-4 text-sm font-medium leading-5 text-gray-900"
                                          >
                                            {value.column}
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {JSON.parse(answer.sheet).map(
                                      (values: any, index: Key | null | undefined) => (
                                        <tr key={index}>
                                          {values.map(
                                            (
                                              value: { column: string; value: string },
                                              index: Key | null | undefined
                                            ) => (
                                              <td
                                                key={index}
                                                className="px-6 py-4 text-sm font-medium leading-5 text-gray-900"
                                              >
                                                {value.value}
                                              </td>
                                            )
                                          )}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </>
                            ) : (
                              answer.value
                            )}
                          </dd>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                            par{' '}
                            <strong>
                              {answer.collaborator ? answer.collaborator.name : customer.name}
                            </strong>{' '}
                            {formatDistance(new Date(answer.updatedAt), new Date(), {
                              addSuffix: true,
                              locale: fr
                            })}
                          </dd>
                        </div>
                      );
                    });
                  })}
              </dl>
            </div>
          </div>
        )}
      </>
    </ModalForm>
  );
};

export default ModalViewAnswers;
