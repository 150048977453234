import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

type Props = {
  title?: string;
  titleInGroup?: string;
  onClick?: () => void;
  icon?: JSX.Element;
  iconSimple?: JSX.Element;
  type: 'primary' | 'secondary' | 'tertiary';
  items?: Array<{ name: string; onClick: () => void; current: boolean }>;
  classNameMenu?: string;
  classNameMenuButton?: string;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const GroupCta = (props: Props) => {
  const {
    title,
    titleInGroup,
    icon,
    iconSimple,
    type,
    items,
    onClick,
    classNameMenu,
    classNameMenuButton
  } = props;

  return (
    <div className="inline-flex rounded-md z-10">
      {title && (
        <button
          type="button"
          onClick={onClick}
          className={`
            ${
              type === 'primary' &&
              `ml-3 inline-flex items-center border border-black bg-white px-4 py-2 text-sm font-normal text-black shadow-sm hover:bg-slate-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-t-white focus:ring-offset-2`
            }
            ${
              type === 'secondary' &&
              'inline-flex items-center rounded-md px-4 py-2 text-sm font-normal text-white hover:bg-slate-400 hover:text-white focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1'
            }
            ${
              type === 'tertiary' &&
              'inline-flex items-center rounded-md rounded-r-none px-4 py-2 text-sm font-normal border border-1 border-black text-black hover:bg-slate-400 hover:text-white focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1'
            }
          `}
        >
          <span className="mr-2">{iconSimple && iconSimple}</span>
          {title}
        </button>
      )}
      {items && (
        <Menu as="div" className="relative -ml-px block">
          <Menu.Button className={`relative inline-flex items-center ${classNameMenuButton || ''}`}>
            {titleInGroup ? (
              <>
                <span className="ml-3">{titleInGroup}</span>
                <span className="ml-3">{icon && icon}</span>
              </>
            ) : (
              <span>{icon && icon}</span>
            )}
            <span className="sr-only">Open options</span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`${
                classNameMenu ||
                'absolute right-0 z-20 mt-2 -mr-1 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
              }`}
            >
              <div className="py-1">
                {items.map((item, idx) => (
                  <Menu.Item key={idx}>
                    {({ active }) => (
                      <button
                        onClick={item.onClick}
                        className={classNames(
                          active || item.current ? 'bg-gray-100 text-black' : 'text-black',
                          'block w-full px-4 py-2 text-sm text-center'
                        )}
                      >
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};

export default GroupCta;
