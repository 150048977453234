import Select from 'components/Select';
import { SheetValues } from '..';
import { PlusIcon, XCircleIcon } from '@heroicons/react/24/outline';
import SheetCreatorItemModalAddPossibleAnswer from './ModalAddPossibleAnswer';
import { useState } from 'react';

type Props = {
  sheetValue: SheetValues | undefined;
  onChangeSheetValues: (sheet: SheetValues) => void;
  onDeleteSheetValues: (sheet: SheetValues) => void;
};

const SheetCreatorItem = (props: Props) => {
  const { sheetValue, onChangeSheetValues, onDeleteSheetValues } = props;

  const [isOpenModalAddPossibleAnswer, setIsOpenModalAddPossibleAnswer] = useState<boolean>(false);
  const [value, setValue] = useState<string>(sheetValue?.name ?? '');
  const [type, setType] = useState<'text' | 'number' | 'multiple_choices' | 'location'>(
    sheetValue?.type ?? 'text'
  );

  if (!sheetValue) return null;

  return (
    <>
      <SheetCreatorItemModalAddPossibleAnswer
        isOpen={isOpenModalAddPossibleAnswer}
        onClose={() => setIsOpenModalAddPossibleAnswer(false)}
        sheetValue={sheetValue}
        onChangeSheetValues={onChangeSheetValues}
      />
      <div>
        <label htmlFor="columns" className="block text-xs font-medium text-gray-900 mb-2">
          Colonne {sheetValue.id}
        </label>
        <div className="flex justify-between items-center space-x-2">
          <div>
            <button
              className="inline-flex items-center gap-x-1.5"
              onClick={() => onDeleteSheetValues(sheetValue)}
            >
              <XCircleIcon className="h-5 w-5 inline-block text-red-500" aria-hidden="true" />
            </button>
          </div>
          <div>
            <input
              type="text"
              className="block w-full p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-white border-0 shadow-sm ring-1 ring-inset ring-gray-300"
              placeholder="Nom de la colonne"
              onChange={(e) => {
                sheetValue.name = e.target.value;
                onChangeSheetValues(sheetValue);
                setValue(e.target.value);
              }}
              value={value}
            />
          </div>
        </div>
      </div>
      <div>
        <label htmlFor="type" className="block text-xs font-medium text-gray-900 mb-2">
          Type
        </label>
        <Select
          selected={type}
          options={[
            { value: 'text', label: 'Texte libre' },
            { value: 'number', label: 'Nombre libre' },
            { value: 'multiple_choices', label: 'Choix multiples' }
          ]}
          onChange={(value: 'text' | 'number' | 'multiple_choices') => {
            sheetValue.type = value;
            onChangeSheetValues(sheetValue);
            setType(value);
          }}
        />
      </div>
      <div>
        <label htmlFor="action" className="block text-xs font-medium text-gray-900 mb-2">
          Action
        </label>
        {type === 'multiple_choices' ? (
          <button
            className="inline-flex items-center gap-x-1.5 rounded-md bg-green-500 px-1.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            onClick={() => setIsOpenModalAddPossibleAnswer(true)}
          >
            <PlusIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            Ajouter des réponses
          </button>
        ) : (
          <p>-</p>
        )}
      </div>
    </>
  );
};

export default SheetCreatorItem;
