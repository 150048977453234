import { useQuery } from '@apollo/client';
import { FilterCollaborator } from 'queryTypes/collaborator';
import {
  useGetAllCollaborators,
  useGetAllCollaboratorsPaginated,
  useGetCollaborator
} from './queries';

const getAllPaginated = (options: FilterCollaborator) =>
  useQuery(useGetAllCollaboratorsPaginated(options), {
    notifyOnNetworkStatusChange: true
  });

const getAll = (options?: FilterCollaborator) =>
  useQuery(useGetAllCollaborators(options), {
    variables: { options },
    notifyOnNetworkStatusChange: true
  });

const getCollaborator = (options?: FilterCollaborator) =>
  useQuery(useGetCollaborator(options), {
    variables: {}
  });

const collaboratorService = {
  getAllPaginated,
  getAll,
  getCollaborator
};

export default collaboratorService;
