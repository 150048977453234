import { useMutation } from '@apollo/client';
import { PlusIcon } from '@heroicons/react/24/outline';
import Loading from 'components/Loading';
import { Customer } from 'queryTypes/customer';
import { FilterCustomer } from 'queryTypes/customer/customer.dto';
import { useCallback, useState } from 'react';
import { useCreateCustomer } from 'services/api/customer/queries';
import Pagination from 'components/Pagination';
import customerService from 'services/api/customer/services';
import ModalAddCustomer from './ModalAddCustomer';
import CustomersItem from './Item';
import { useSearchParams } from 'react-router-dom';
import { handleUpload } from 'utils';
import organizationStore from 'stores/Organization';

const Customers = () => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const [queryParameters] = useSearchParams();
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [pageSizeValue, setPageSizeValue] = useState<number>(10);
  const [filter, setFilter] = useState<FilterCustomer>({
    page: 1,
    per_page: pageSizeValue,
    workspaceId: currentOrganization?.id,
    orderByField: 'updatedAt',
    orderByDirection: 'DESC',
    name: queryParameters.get('search') ?? ''
  });

  // Query customers
  const {
    data: customers,
    refetch,
    error,
    loading: isLoading
  } = customerService.getAllPaginated(filter);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [createCustomer, { loading: isLoadingCreateCustomer }] = useMutation(useCreateCustomer);

  const handleSubmitCreateCustomer = useCallback(
    async (
      name: string,
      email: string,
      phone: string,
      address1: string,
      zipCode: string,
      city: string,
      country: string,
      address2?: string,
      selectedFile?: any
    ) => {
      if (!currentOrganization || isLoadingCreateCustomer) return;
      if (selectedFile) {
        const avatarUrl = await handleUpload(selectedFile);
        setAvatarUrl(avatarUrl);
      }
      createCustomer({
        variables: {
          customer: {
            name,
            email,
            phone,
            address1,
            address2,
            zipCode,
            city,
            country,
            avatarUrl,
            workspaceId: currentOrganization.id
          }
        },
        onCompleted: (data) => {
          if (data && !data.createCustomer.errors) {
            setIsModalOpen(false);
            setAvatarUrl('');
            refetch();
          }
        }
      });
    },
    [isLoadingCreateCustomer, avatarUrl]
  );

  const setPageSize = useCallback((pageSize: number) => {
    setPageSizeValue(pageSize);
    setFilter((prevFilter) => ({ ...prevFilter, per_page: pageSize }));
  }, []);

  const setPage = useCallback((page: number) => {
    setFilter((prevFilter) => ({ ...prevFilter, page: page }));
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {isLoading ? (
        <div className="h-5">
          <Loading />
        </div>
      ) : (
        <>
          <div className=" bg-white mb-20">
            <div className="flex flex-row justify-between items-center mx-auto px-20 pt-10">
              <h1 className="text-3xl font-normal text-black">Clients</h1>
            </div>
            <div className="w-full mt-20 px-4 sm:px-6 lg:px-8">
              <div className="max-w-6xl mx-auto">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <p className="mt-2 text-sm text-gray-700">
                      Liste des clients{' '}
                      <strong>({customers?.paginatedCustomers?.totalCount})</strong>
                    </p>
                  </div>
                  <div className="w-56 mt-4 sm:mt-0 sm:flex-none">
                    <input
                      type="text"
                      name="name"
                      onChange={(e) =>
                        setFilter((prevFilter) => ({ ...prevFilter, name: e.target.value }))
                      }
                      value={filter.name}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Indiquez un nom de client"
                    />
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                      onClick={() => setIsModalOpen(true)}
                      className="flex flex-row justify-between items-center space-x-2 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <div>
                        <PlusIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                      </div>
                      <div>Nouveau</div>
                    </button>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                            >
                              Nom
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                            >
                              Email
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                            >
                              Téléphone
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                            >
                              Questionnaires
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                            >
                              Réponses
                            </th>
                            <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                              <span className="sr-only">Modifier</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {customers?.paginatedCustomers.data.map(
                            (customer: Customer, index: number) => (
                              <CustomersItem customer={customer} key={index} refetch={refetch} />
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalAddCustomer
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleSubmitCreateCustomer}
          />
          {customers?.paginatedCustomers.totalCount > 0 && (
            <Pagination
              title="clients(s)"
              page={customers?.paginatedCustomers.page}
              totalCount={customers?.paginatedCustomers.totalCount}
              pageSize={pageSizeValue}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          )}
        </>
      )}
    </>
  );
};

export default Customers;
