import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Auth0Provider
    domain="dev-ie1bke5eonaj378r.eu.auth0.com"
    clientId="OsA856deczY0cWfQCJcnDPC4E0SQ6t8C"
    authorizationParams={{ redirect_uri: window.location.origin }}
    useRefreshTokens={true}
  >
    <App />
  </Auth0Provider>
);
