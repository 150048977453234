import {
  BookOpenIcon,
  ChartPieIcon,
  HomeIcon,
  ShoppingBagIcon,
  UserGroupIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import {
  BookOpenIcon as BookOpenIconSolid,
  ChartPieIcon as ChartPieIconSolid,
  ShoppingBagIcon as ShoppingBagIconSolid,
  HomeIcon as HomeIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  UserIcon as UserIconSolid
} from '@heroicons/react/24/solid';
import { Fragment, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { navUser } from 'types/navUser';

type Props = {
  minified: boolean;
};

const Nav = ({ minified }: Props): JSX.Element => {
  const location = useLocation();

  const classNames = useCallback((...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  }, []);

  const navigation = [
    {
      name: 'Tableau de bord',
      to: '/',
      icon: HomeIcon,
      iconActive: HomeIconSolid,
      current: location.pathname === '/' ?? false
    },
    {
      name: 'Questionnaires',
      to: '/forms',
      icon: BookOpenIcon,
      iconActive: BookOpenIconSolid,
      current: location.pathname.match(/\/forms/) ? true : false
    },
    {
      name: 'Statistiques',
      to: '/statistics',
      icon: ChartPieIcon,
      iconActive: ChartPieIconSolid,
      current: location.pathname.match(/\/statistics/) ? true : false
    },
    {
      name: 'Clients',
      to: '/customers',
      icon: UserIcon,
      iconActive: UserIconSolid,
      current: location.pathname.match(/\/customers/) ? true : false,
      subMenu: [
        {
          name: 'Collaborateurs',
          to: '/collaborators',
          current: location.pathname.match(/\/collaborators/) ? true : false,
          icon: UserGroupIcon,
          iconActive: UserGroupIconSolid
        }
      ]
    },
    {
      name: 'Catalogue',
      to: '/catalog',
      icon: ShoppingBagIcon,
      iconActive: ShoppingBagIconSolid,
      current: location.pathname.match(/\/catalog/) ? true : false
    }
  ];

  return (
    <nav className="flex-1 space-y-1">
      {navigation.map((item: navUser) => (
        <Fragment key={item.name}>
          <Link
            to={item.to}
            className={classNames(
              item.current
                ? 'bg-gray-600 text-white'
                : 'bg-white text-black hover:bg-gray-600 hover:text-white',
              'group flex items-center px-2 py-2 text-sm rounded-md'
            )}
          >
            {item.current ? (
              <item.iconActive className={`mr-4 h-6 w-6 text-gray-200 ${minified ? 'ml-4' : ''}`} />
            ) : (
              <item.icon
                className={classNames(
                  item.current
                    ? `bg-gray-600 text-white mr-3`
                    : `text-gray-400 hover:bg-gray-600 mr-3 hover:text-white',
                  'mr-3 h-6 w-6 flex-shrink-0 ${minified ? 'ml-4' : ''}`
                )}
                aria-hidden="true"
              />
            )}
            {minified ? '' : item.name}
          </Link>
          {item.subMenu?.map((item: navUser, index: number) => (
            <Link
              key={index}
              to={item.to}
              className={classNames(
                item.current
                  ? 'bg-gray-600 text-white'
                  : 'bg-white text-black hover:bg-gray-600 hover:text-white',
                'group flex items-center px-2 py-2 text-sm rounded-md'
              )}
            >
              {item.current ? (
                <item.iconActive
                  className={`mr-4 h-6 w-6 text-gray-200 ${minified ? 'ml-4' : ''}`}
                />
              ) : (
                <item.icon
                  className={classNames(
                    item.current
                      ? `bg-gray-600 text-white mr-3`
                      : `text-gray-400 hover:bg-gray-600 mr-3 hover:text-white',
                    'mr-3 h-6 w-6 flex-shrink-0 ${minified ? 'ml-4' : ''}`
                  )}
                  aria-hidden="true"
                />
              )}
              {minified ? '' : item.name}
            </Link>
          ))}
        </Fragment>
      ))}
    </nav>
  );
};

export default Nav;
