import { gql } from '@apollo/client';
import { FilterQuestion } from 'queryTypes/question';

export const useGetAllQuestions = (options?: FilterQuestion) => gql`
  query getQuestions {
    questions(filter: "${options?.formId ? `form_id == '${options?.formId}'` : ``}"${
      options?.orderByField
        ? `, orderBy: "${options?.orderByField} ${options?.orderByDirection}"`
        : ``
    }) {
      id
      text
      helpMessage
      orderIndex
      answerType
      estimatedTimeToAnswer
      sheetFormula
      sheetTemplate
      possibleAnswers {
        id
        value
        label
      }
      excelMappings {
        id
        excelBaseColumn
        excelBaseLine
        excelTab
        insert
        orderIndex
        value
      }
      collaborators {
        id
        name
        role
      }
    }
  }
`;

export const useCreateQuestion = gql`
  mutation createQuestion($question: QuestionInputType!) {
    createQuestion(attributes: $question) {
      question {
        id
        text
        helpMessage
        orderIndex
        answerType
        estimatedTimeToAnswer
        sheetFormula
        sheetTemplate
        possibleAnswers {
          id
          value
          label
        }
        excelMappings {
          id
          excelBaseColumn
          excelBaseLine
          excelTab
          insert
          orderIndex
          value
        }
        collaborators {
          id
          name
          role
        }
      }
      errors
    }
  }
`;

export const useUpdateQuestion = gql`
  mutation updateQuestion($questionId: QuestionId!, $question: QuestionInputType!) {
    updateQuestion(id: $questionId, attributes: $question) {
      question {
        id
      }
      errors
    }
  }
`;

export const useGetQuestion = (options: FilterQuestion) => gql`
  query getQuestion {
    question(id: "${options.id}") {
      id
      text
      helpMessage
      orderIndex
      answerType
      estimatedTimeToAnswer
      sheetFormula
      sheetTemplate
      possibleAnswers {
        id
        value
        label
      }
      excelMappings {
        id
        excelBaseColumn
        excelBaseLine
        excelTab
        insert
        orderIndex
        value
      }
      collaborators {
        id
        name
        role
      }
    }
  }
`;

export const useDestroyQuestion = gql`
  mutation destroyQuestion($questionId: QuestionId!) {
    destroyQuestion(id: $questionId) {
      question {
        id
      }
      errors
    }
  }
`;

export const useDuplicateQuestion = gql`
  mutation duplicateQuestion($questionId: QuestionId!, $text: String!) {
    duplicateQuestion(id: $questionId, text: $text) {
      question {
        id
        text
      }
      errors
    }
  }
`;
