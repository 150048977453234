import { useQuery } from '@apollo/client';
import { useGetAllQuestions, useGetQuestion } from './queries';
import { FilterQuestion } from 'queryTypes/question';

const getAll = (options?: FilterQuestion) =>
  useQuery(useGetAllQuestions(options), {
    variables: { options },
    notifyOnNetworkStatusChange: true
  });

const getQuestion = (options: FilterQuestion) =>
  useQuery(useGetQuestion(options), {
    notifyOnNetworkStatusChange: true
  });
const questionService = {
  getAll,
  getQuestion
};

export default questionService;
