import ModalForm from 'components/ModalForm';
import { Question } from 'queryTypes/question';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (question: Question, name: string) => void;
  question: Question;
};
const ModalDuplicateQuestion = (props: Props) => {
  const { isOpen, onClose, onSubmit, question } = props;
  const [text, setText] = useState<string>('');

  return (
    <ModalForm
      title={`Dupliquez la question ${question.text}`}
      ctaTitle="Dupliquer"
      size="md"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit(question, text)}
      onLoading={text === ''}
      withCloseButton
    >
      <div className="flex flex-col space-y-5">
        <div>
          <input
            type="text"
            name="text"
            onChange={(e) => setText(e.target.value)}
            value={text}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Intitulé de votre question"
          />
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalDuplicateQuestion;
