import ComboBox from 'components/ComboBox';
import FileUpload from 'components/FileUpload';
import ModalForm from 'components/ModalForm';
import { Customer } from 'queryTypes/customer';
import { useState } from 'react';
import { countryList } from 'utils';

type Props = {
  customer: Customer;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    name: string,
    email: string,
    phone: string,
    address1: string,
    zipCode: string,
    city: string,
    country: string,
    address2?: string,
    selectedFile?: any
  ) => void;
};
const ModalEditCustomer = (props: Props) => {
  const { customer, isOpen, onClose, onSubmit } = props;
  const [name, setName] = useState<string>(customer.name);
  const [email, setEmail] = useState<string>(customer.email);
  const [phone, setPhone] = useState<string>(customer.phone);
  const [address1, setAddress1] = useState<string>(customer.address1);
  const [address2, setAddress2] = useState<string>(customer.address2);
  const [zipCode, setZipCode] = useState<string>(customer.zipCode);
  const [city, setCity] = useState<string>(customer.city);
  const [country, setCountry] = useState<string>(customer.country);
  const [selectedFile, setSelectedFile] = useState<any>();

  return (
    <ModalForm
      title={`Modifiez le client ${customer.name}`}
      ctaTitle="Enregistrer"
      size="md"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() =>
        onSubmit(name, email, phone, address1, zipCode, city, country, address2, selectedFile)
      }
      onLoading={
        name === '' ||
        email === '' ||
        phone === '' ||
        address1 === '' ||
        zipCode === '' ||
        city === '' ||
        country === ''
      }
      withCloseButton
    >
      <div className="flex flex-col space-y-5">
        <div>
          <FileUpload
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            defaultFile={customer.avatarUrl}
          />
        </div>
        <div>
          <div className="sm:col-span-4">
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
              Nom du client
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Champagne Lanson"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start items-center space-x-5">
          <div className="sm:col-span-4">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Email du client
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="john.doe@example.com"
              />
            </div>
          </div>
          <div className="sm:col-span-4">
            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
              Téléphone du client
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="phone"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                className="block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0612345678"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="sm:col-span-4">
            <label htmlFor="address1" className="block text-sm font-medium leading-6 text-gray-900">
              Adresse du client
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="address1"
                onChange={(e) => setAddress1(e.target.value)}
                value={address1}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="1 rue de Rivoli"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="sm:col-span-4">
            <label htmlFor="address2" className="block text-sm font-medium leading-6 text-gray-900">
              Complément d&apos;adresse
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="address2"
                onChange={(e) => setAddress2(e.target.value)}
                value={address2}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Bat b, étage 2, porte 3"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start items-center space-x-5">
          <div className="sm:col-span-4">
            <label htmlFor="zipCode" className="block text-sm font-medium leading-6 text-gray-900">
              Code postal
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="zipCode"
                onChange={(e) => setZipCode(e.target.value)}
                value={zipCode}
                className="block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="75001"
              />
            </div>
          </div>
          <div className="sm:col-span-4">
            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
              Ville
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="city"
                onChange={(e) => setCity(e.target.value)}
                value={city}
                className="block w-40 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Paris"
              />
            </div>
          </div>
          <ComboBox
            label="Pays"
            selected={country}
            onChange={(value) => setCountry(value)}
            options={countryList.map((country) => ({ label: country, value: country }))}
          />
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalEditCustomer;
