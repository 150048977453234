import ModalForm from 'components/ModalForm';
import { Form } from 'queryTypes/form';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: Form, name: string, redirect: boolean) => void;
  form: Form;
};
const ModalDuplicateForm = (props: Props) => {
  const { isOpen, onClose, onSubmit, form } = props;
  const [sector, setSector] = useState<string>('');
  const [redirect, setRedirect] = useState<boolean>(true);

  return (
    <ModalForm
      title={`Dupliquez le formulaire du secteur ${form.sector}`}
      ctaTitle="Dupliquer"
      size="md"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit(form, sector, redirect)}
      onLoading={sector === ''}
      withCloseButton
    >
      <div className="flex flex-col space-y-5">
        <div>
          <input
            type="text"
            name="sector"
            onChange={(e) => setSector(e.target.value)}
            value={sector}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Nom du secteur"
          />
        </div>
        <div className="space-y-5">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="redirect"
                aria-describedby="comments-description"
                name="redirect"
                type="checkbox"
                checked={redirect}
                onClick={() => setRedirect(!redirect)}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-gray-900">
                Modifier le questionnaire
              </label>
              <p id="comments-description" className="text-gray-500">
                Lors de la duplication nous vous redirigerons vers le questionnaire de modification
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalDuplicateForm;
