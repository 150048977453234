import { useCallback, useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

type Props = {
  title: string;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  totalCount: number;
};

const Pagination = (props: Props) => {
  const { title, page, setPage, pageSize, setPageSize, totalCount } = props;
  const [pageCount, setPageCount] = useState(0);
  const [pageSizeOptions] = useState([10, 20, 30, 40, 50]);

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / pageSize));
  }, [totalCount, pageSize]);

  const handlePageChange = useCallback((page: number) => {
    setPage(page);
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
  }, []);

  return (
    <div className="xl:fixed relative bottom-0 border-t border-gray-200 bg-white py-3 w-full z-30">
      <div className="flex flex-wrap justify-between items-center px-6">
        <div className="flex flex-wrap items-center flex-1 xl:order-start">
          <p className="text-sm text-black font-normal">
            <select
              id="page-size"
              name="page-size"
              className="border border-gray-200 rounded-md shadow-sm py-2 bg-white text-sm focus:outline-none focus:ring-gray-200 focus:border-gray-200"
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            >
              {pageSizeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <span className="ml-2">par page</span>
          </p>
          <p className="text-sm text-black font-normal px-10">
            Page {page} sur {pageCount} pour {totalCount} {title}
          </p>
        </div>
        {pageCount > 1 && (
          <div className="flex-1 xl:order-last">
            <nav
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              {pageCount > 1 && page !== 1 && (
                <a
                  href="#"
                  onClick={() => handlePageChange(page - 1)}
                  className="relative z-10 inline-flex items-center rounded-l-md border border-gray-200 bg-white px-2 py-2 text-sm font-medium text-black hover:border-gray-400 hover:bg-gray-200 hover:z-20 focus:z-20"
                >
                  <span className="sr-only">Précédent</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              )}
              {pageCount <= 6 ? (
                Array.from({ length: pageCount }, (_, i) => {
                  return (
                    <a
                      key={i}
                      href="#"
                      onClick={() => handlePageChange(i + 1)}
                      aria-current="page"
                      className={`relative inline-flex items-center border ${
                        page !== i + 1
                          ? `z-10 border-gray-200 bg-white`
                          : `z-20 border-gray-400 bg-gray-200 hover:z-20`
                      } px-4 py-2 text-sm font-medium text-black hover:border-gray-400 hover:bg-gray-200 hover:z-20 focus:z-20`}
                    >
                      {i + 1}
                    </a>
                  );
                })
              ) : (
                <>
                  <a
                    href="#"
                    onClick={() => handlePageChange(1)}
                    className={`relative inline-flex items-center border ${
                      page !== 1
                        ? `z-10 border-gray-200 bg-white`
                        : `z-20 border-gray-400 bg-gray-200 hover:z-20`
                    } px-4 py-2 text-sm font-medium text-black hover:border-gray-400 hover:bg-gray-200 hover:z-20 focus:z-20`}
                  >
                    1
                  </a>
                  <a
                    href="#"
                    onClick={() => handlePageChange(2)}
                    className={`relative inline-flex items-center border ${
                      page !== 2
                        ? `z-10 border-gray-200 bg-white`
                        : `z-20 border-gray-400 bg-gray-200 hover:z-20`
                    } px-4 py-2 text-sm font-medium text-black hover:border-gray-400 hover:bg-gray-200 hover:z-20 focus:z-20`}
                  >
                    2
                  </a>
                  <a
                    href="#"
                    className="relative inline-flex items-center border border-gray-200 bg-t-white px-4 py-2 text-sm font-medium text-black cursor-default"
                  >
                    ...
                  </a>
                  {page > 2 && (
                    <a
                      href="#"
                      className="relative inline-flex items-center border z-20 border-gray-400 bg-gray-200 hover:z-20 px-4 py-2 text-sm font-medium"
                    >
                      {page}
                    </a>
                  )}
                  {Array.from({ length: pageCount }, (_, i) => {
                    return (
                      <a
                        key={i}
                        href="#"
                        onClick={() => handlePageChange(i + 1)}
                        className={`relative inline-flex items-center border ${
                          page !== i + 1
                            ? `z-10 border-gray-200 bg-white`
                            : `z-20 border-gray-400 bg-gray-200 hover:z-20`
                        } px-4 py-2 text-sm font-medium text-black hover:border-gray-400 hover:bg-gray-200 hover:z-20 focus:z-20`}
                      >
                        {i + 1}
                      </a>
                    );
                  }).slice(
                    page >= 2 ? page : page + 1,
                    page + 3 === pageCount ? pageCount : page >= 2 ? page + 3 : page + 4
                  )}
                </>
              )}
              {pageCount > 1 && page !== pageCount && (
                <a
                  href="#"
                  onClick={() => handlePageChange(page + 1)}
                  className="relative z-10 inline-flex items-center rounded-r-md border border-gray-200 bg-white px-2 py-2 text-sm font-medium text-black hover:border-gray-400 hover:bg-gray-200 hover:z-20 focus:z-20"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              )}
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
