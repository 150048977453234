import { useQuery } from '@apollo/client';
import { FilterCustomer } from 'queryTypes/customer';
import { useGetAllCustomers, useGetAllCustomersPaginated, useGetCustomer } from './queries';

const getAllPaginated = (options: FilterCustomer) =>
  useQuery(useGetAllCustomersPaginated(options), {
    notifyOnNetworkStatusChange: true
  });

const getAll = (options?: FilterCustomer) =>
  useQuery(useGetAllCustomers(options), {
    variables: { options },
    notifyOnNetworkStatusChange: true
  });

const getCustomer = (options?: FilterCustomer) =>
  useQuery(useGetCustomer(options), {
    variables: {}
  });

const customerService = {
  getAllPaginated,
  getAll,
  getCustomer
};

export default customerService;
