import ModalForm from 'components/ModalForm';
import { XMarkIcon } from '@heroicons/react/20/solid';
import organizationStore from 'stores/Organization';
import { Collaborator } from 'queryTypes/collaborator';
import Select from 'components/Select';
import { Question } from 'queryTypes/question';
import { Fragment, useCallback, useMemo, useState } from 'react';
import ModalDelete from 'components/ModalDelete';
import { useMutation } from '@apollo/client';
import { useUpdateQuestion } from 'services/api/question/queries';
import { PlusIcon } from '@heroicons/react/24/outline';

type Props = {
  collaborator: Collaborator;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
};

const ModalAssociate = (props: Props) => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const { isOpen, onClose, collaborator, refetch } = props;
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);

  const listQuestionWithoutCollaborator = useMemo(
    () =>
      collaborator.customers[0]?.forms[0]?.questions?.filter(
        (question: Question) =>
          !question.collaborators.find(
            (questionCollaborator: Collaborator) => questionCollaborator.id === collaborator.id
          )
      ),
    [collaborator]
  );

  const listQuestionWithCollaborator = useMemo(
    () =>
      collaborator.customers[0]?.forms[0]?.questions?.filter((question: Question) =>
        question.collaborators.find(
          (questionCollaborator: Collaborator) => questionCollaborator.id === collaborator.id
        )
      ),
    [collaborator]
  );

  const [selected, setSelected] = useState<string>(
    listQuestionWithoutCollaborator ? listQuestionWithoutCollaborator[0]?.id : ''
  );

  const [updateQuestion, { loading: isLoadingUpdateQuestion }] = useMutation(useUpdateQuestion);

  const handleDestroyCollaborator = useCallback(
    (collaborator: Collaborator, question: Question) => {
      if (isLoadingUpdateQuestion) return;
      updateQuestion({
        variables: {
          questionId: question.id,
          question: {
            collaboratorIds: question.collaborators
              .filter(
                (questionCollaborator: Collaborator) => questionCollaborator.id !== collaborator.id
              )
              .map((questionCollaborator: Collaborator) => questionCollaborator.id)
          }
        },
        onCompleted: (data) => {
          if (data && !data.updateQuestion.errors) {
            refetch();
            setSelected(question.id);
          }
        }
      });
    },
    [isLoadingUpdateQuestion]
  );

  const handleAddCollaborator = useCallback(() => {
    if (isLoadingUpdateQuestion) return;
    if (!selected) return;

    const question = collaborator.customers[0]?.forms[0]?.questions?.find(
      (question: Question) => question.id === selected
    );

    if (!question) return;

    updateQuestion({
      variables: {
        questionId: question.id,
        question: {
          collaboratorIds: [
            ...question.collaborators
              .filter(
                (questionCollaborator: Collaborator) => questionCollaborator.id !== collaborator.id
              )
              .map((questionCollaborator: Collaborator) => questionCollaborator.id),
            collaborator.id
          ]
        }
      },
      onCompleted: (data) => {
        if (data && !data.updateQuestion.errors) {
          refetch();
          setSelected(listQuestionWithoutCollaborator[0]?.id);
        }
      }
    });
  }, [isLoadingUpdateQuestion, selected, collaborator, listQuestionWithoutCollaborator]);

  return (
    <ModalForm
      title={`Associez un questionnaire à un client`}
      ctaTitle="J'ai terminé"
      size="md"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onClose()}
      withCloseButton
    >
      <div className="flex flex-col space-y-5">
        <div>
          <p className="mb-2">
            Sélectionnez une question à ajouter au collaborateur {collaborator.name}:
          </p>
          <Select
            selected={selected}
            onChange={(value) => setSelected(value)}
            options={listQuestionWithoutCollaborator?.map((question: Question) => ({
              label: question.text,
              value: question.id
            }))}
          />
          {selected && (
            <div className="flex justify-end items-center mt-5">
              <button
                onClick={handleAddCollaborator}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                <PlusIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                Ajouter
              </button>
            </div>
          )}
        </div>
        <div className="mt-8 mb-10 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Intitulé
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Supprimer</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {listQuestionWithCollaborator?.map((question: Question, index: number) => (
                    <Fragment key={index}>
                      <tr className="hidden">
                        <td>
                          <ModalDelete
                            title="Supprimer un collaborateur"
                            isOpen={isOpenModalDelete}
                            onClose={() => setIsOpenModalDelete(false)}
                            onSubmit={() => handleDestroyCollaborator(collaborator, question)}
                          >
                            le collaborateur de la question {question.text}
                          </ModalDelete>
                        </td>
                      </tr>
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {question.text}
                        </td>
                        <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <button
                            onClick={() => setIsOpenModalDelete(true)}
                            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          >
                            <XMarkIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                            Supprimer
                          </button>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalAssociate;
