import { Fragment, ReactNode, SetStateAction, useCallback, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Bars3BottomLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import Nav from './Nav';
import NavMobile from './Nav/Mobile';
import NavUser from './Nav/User';
import { useAuth0 } from '@auth0/auth0-react';
import { ApolloClient } from '@apollo/client';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import OrganizationSelector from './OrganizationSelector';

type Props = {
  children: ReactNode;
  client: ApolloClient<any>;
  withNavigation?: boolean;
};

const Layout = (props: Props): JSX.Element => {
  const { children, client, withNavigation = true } = props;
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [minified, setMinified] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { user } = useAuth0();
  const navigate = useNavigate();

  const handleSearch = useCallback((e: { target: { value: SetStateAction<string> } }) => {
    setSearch(e.target.value);
  }, []);

  const handleKeyDown = useCallback((e: { key: string }) => {
    if (e.key === 'Enter') {
      navigate(`/customers?search=${search}`);
    }
  }, []);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-t-white bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-600 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <Link to="/">
                    <img src="/logo.svg" className="w-32 -mt-3" alt="logo" />
                  </Link>
                </div>
                <NavMobile />
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={`hidden md:fixed md:inset-y-0 md:flex ${
          minified ? 'md:w-24' : 'md:w-64'
        } md:flex-col`}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-0 flex-col bg-gray-600">
          <div className="flex h-16 flex-shrink-0 justify-start items-center px-4 pl-5">
            {withNavigation && (
              <div>
                <button
                  type="button"
                  className="px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  onClick={() => setMinified(!minified)}
                >
                  <span className="sr-only">Minified</span>
                  <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            )}
            <div>
              {!minified && (
                <Link to="/">
                  <img src="/logo.svg" className="w-32 -mt-3" alt="logo" />
                </Link>
              )}
            </div>
          </div>
        </div>
        {withNavigation && (
          <div className="flex flex-1 flex-col pl-2 overflow-y-auto bg-white border-r-[1px] border-gray-400 w-full">
            <div className="flex flex-col px-2 pb-6 py-10">
              <Nav minified={minified} />
            </div>
          </div>
        )}
      </div>
      <div className={`flex flex-1 flex-col ${minified ? 'md:pl-24' : 'md:pl-64'}`}>
        <div className="sticky top-0 z-20 flex h-16 flex-shrink-0 bg-gray-600">
          <button
            type="button"
            className="px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4">
            <div className="flex flex-1">
              <form className="flex w-full md:ml-0" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative lg:w-[30rem] sm:w-full h-10 mt-3 text-white focus-within:text-gray-400">
                  <div className="pointer-events absolute inset-y-0 left-4 flex items-center">
                    <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className="block h-full w-full placeholder-gray-400 rounded border-gray-600 py-2 pl-[3rem] pr-3 bg-gray-700 text-white focus:border-gray-300 focus:placeholder-white focus:outline-none sm:text-sm"
                    placeholder="Recherchez un client ... "
                    type="search"
                    name="search"
                    onChange={(e) => handleSearch(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </div>
              </form>
            </div>
            <OrganizationSelector clientApollo={client} />
            <div className="ml-4 flex items-center md:ml-6">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm">
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full" src={user?.picture} alt="" />
                  </Menu.Button>
                </div>
                <NavUser />
              </Menu>
            </div>
          </div>
        </div>
        {withNavigation && <main>{children}</main>}
      </div>
      {!withNavigation && <main>{children}</main>}
    </div>
  );
};

export default observer(Layout);
