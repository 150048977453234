import deepfreeze from 'deep-freeze';

export default deepfreeze({
  APP_VERSION: process.env.REACT_APP_VERSION,
  API_URL_GRAPHQL: process.env.REACT_APP_API_URL_GRAPHQL,
  API_URL: process.env.REACT_APP_API_URL,
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  CARBONO_API: process.env.REACT_APP_CARBONO_API
});
