import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { RocketLaunchIcon, XMarkIcon } from '@heroicons/react/24/outline';

type Props = {
  children?: React.ReactNode;
  title: string;
  ctaTitle?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  isOpen: boolean;
  onClose: () => void;
  onSubmit(): any;
  onLoading?: boolean;
  withCloseButton?: boolean;
};

const ModalForm = (props: Props) => {
  const {
    children,
    title,
    ctaTitle,
    size = 'sm',
    isOpen,
    onClose,
    onSubmit,
    onLoading,
    withCloseButton
  } = props;

  const getSize = () => {
    switch (size) {
      case 'sm':
        return 'w-[20rem]';
      case 'md':
        return 'w-[40rem]';
      case 'lg':
        return 'w-[60rem]';
      case 'xl':
        return 'w-[80rem]';
      case '2xl':
        return 'w-[100rem]';
      default:
        return 'w-[40rem]';
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="z-50 fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white px-10 pt-5 pb-4 text-left shadow-xl transition-all my-8 ${getSize()} p-6`}
              >
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title as="h3" className="text-3xl font-medium leading-8 text-black">
                      {title}
                    </Dialog.Title>
                    <div className="mt-10">
                      {children}
                      <div className="flex flex-row justify-between items-center mt-5 sm:mt-6">
                        <div className="justify-start">
                          {withCloseButton && (
                            <button
                              type="button"
                              onClick={onClose}
                              className="inline-flex items-center text-white font-bold py-2 px-4 rounded bg-red-500"
                            >
                              <XMarkIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                              Annuler
                            </button>
                          )}
                        </div>
                        {ctaTitle && (
                          <div className="justify-end">
                            <button
                              type="submit"
                              onClick={onSubmit}
                              className={`inline-flex items-center text-white font-bold py-2 px-4 rounded ${
                                onLoading ? 'bg-blue-200' : 'bg-blue-500'
                              }`}
                              disabled={onLoading}
                            >
                              <RocketLaunchIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                              {ctaTitle}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalForm;
