import { useQuery } from '@apollo/client';
import { useGetAllPossibleAnswer, useGetPossibleAnswer } from './queries';
import { FilterPossibleAnswer } from 'queryTypes/possibleAnswer';

const getAll = (options?: FilterPossibleAnswer) =>
  useQuery(useGetAllPossibleAnswer(options), {
    variables: { options },
    notifyOnNetworkStatusChange: true
  });

const getPossibleAnswer = (options: FilterPossibleAnswer) =>
  useQuery(useGetPossibleAnswer(options), {
    notifyOnNetworkStatusChange: true
  });
const possibleAnswerService = {
  getAll,
  getPossibleAnswer
};

export default possibleAnswerService;
