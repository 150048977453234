import { gql } from '@apollo/client';
import { FilterWorkspace } from 'queryTypes/workspace';

export const useGetAllWorkspaces = (options: FilterWorkspace) => gql`
  query getWorkspaces {
    workspaces(filter: "users.id == '${options.userId}'") {
      id
      name
      users {
        id
        firstName
        lastName
      }
      forms {
        id
        sector
      }
      customers {
        id
        name
      }
    }
  }
`;

export const useGetWorkspace = (options: FilterWorkspace) => gql`
  query getWorkspace {
    workspace(id: "${options.id}") {
      id
      users {
        id
        firstName
        lastName
      }
      forms {
        id
        sector
      }
      customers {
        id
        name
      }
    }
  }
`;

export const useCreateWorkspace = gql`
  mutation createWorkspace($workspace: WorkspaceInputType!) {
    createWorkspace(attributes: $workspace) {
      workspace {
        id
        name
        users {
          id
          firstName
          lastName
        }
        forms {
          id
          sector
        }
        customers {
          id
          name
        }
      }
      errors
    }
  }
`;

export const useAssociateWorkspaceUser = gql`
  mutation createWorkspaceUser($workspaceUser: WorkspaceUserInputType!) {
    createWorkspaceUser(attributes: $workspaceUser) {
      workspaceUser {
        id
      }
      errors
    }
  }
`;
