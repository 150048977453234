import { useQuery } from '@apollo/client';
import { FilterWorkspace } from 'queryTypes/workspace';
import { useGetAllWorkspaces, useGetWorkspace } from './queries';

const getAllWorkspace = (options: FilterWorkspace) => useQuery(useGetAllWorkspaces(options), {});
const getOneWorkspace = (options: FilterWorkspace) => useQuery(useGetWorkspace(options), {});

const workspaceService = {
  getAllWorkspace,
  getOneWorkspace
};

export default workspaceService;
