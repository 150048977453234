import { useAuth0 } from '@auth0/auth0-react';
import authStore from 'stores/Auth';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
      className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
      onClick={() => {
        logout({ logoutParams: { returnTo: window.location.origin } });
        authStore.logout();
      }}
    >
      Déconnexion
    </button>
  );
};

export default LogoutButton;
