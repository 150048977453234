import {
  BookOpenIcon,
  ChartPieIcon,
  HomeIcon,
  ShoppingBagIcon,
  UserGroupIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import {
  BookOpenIcon as BookOpenIconSolid,
  ChartPieIcon as ChartPieIconSolid,
  ShoppingBagIcon as ShoppingBagIconSolid,
  HomeIcon as HomeIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  UserIcon as UserIconSolid
} from '@heroicons/react/24/solid';
import { Fragment, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { navUser } from 'types/navUser';

const NavMobile = (): JSX.Element => {
  const location = useLocation();

  const classNames = useCallback((...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  }, []);

  const navigation: navUser[] = [
    {
      name: 'Tableau de bord',
      to: '/',
      icon: HomeIcon,
      iconActive: HomeIconSolid,
      current: location.pathname === '/' ?? false
    },
    {
      name: 'Questionnaires',
      to: '/forms',
      icon: BookOpenIcon,
      iconActive: BookOpenIconSolid,
      current: location.pathname === '/forms' ?? false
    },
    {
      name: 'Statistiques',
      to: '/statistics',
      icon: ChartPieIcon,
      iconActive: ChartPieIconSolid,
      current: location.pathname.match(/\/statistics/) ? true : false
    },
    {
      name: 'Clients',
      to: '/customers',
      icon: UserIcon,
      iconActive: UserIconSolid,
      current: location.pathname.match(/\/customers/) ? true : false,
      subMenu: [
        {
          name: 'Collaborateurs',
          to: '/collaborators',
          current: location.pathname.match(/\/collaborators/) ? true : false,
          icon: UserGroupIcon,
          iconActive: UserGroupIconSolid
        }
      ]
    },
    {
      name: 'Catalogue',
      to: '/catalog',
      icon: ShoppingBagIcon,
      iconActive: ShoppingBagIconSolid,
      current: location.pathname.match(/\/catalog/) ? true : false
    }
  ];

  return (
    <div className="mt-5 h-0 flex-1 overflow-y-auto">
      <nav className="space-y-1 px-2">
        {navigation.map((item: navUser) => (
          <Fragment key={item.name}>
            <Link
              key={item.name}
              to={item.to}
              className={classNames(
                item.current
                  ? 'bg-white text-gray-400'
                  : 'text-gray-400 hover:text-gray-400 hover:bg-white',
                'group flex items-center px-2 py-2 text-sm rounded-md'
              )}
            >
              {item.current ? (
                <item.iconActive
                  className="flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              ) : (
                <item.icon
                  className="mr-4 h-6 w-6 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
              )}
              {item.name}
            </Link>
            {item.subMenu?.map((item: navUser, index: number) => (
              <Link
                key={index}
                to={item.to}
                className={classNames(
                  item.current
                    ? 'bg-white text-gray-400'
                    : 'text-gray-400 hover:text-gray-400 hover:bg-white',
                  'group flex items-center px-2 py-2 text-sm rounded-md'
                )}
              >
                {item.current ? (
                  <item.iconActive className="flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-400" />
                ) : (
                  <item.icon
                    className="mr-4 h-6 w-6 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                {item.name}
              </Link>
            ))}
          </Fragment>
        ))}
      </nav>
    </div>
  );
};

export default NavMobile;
