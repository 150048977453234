export enum TabsEnum {
  DESCRIPTIF = 'Descriptif',
  ENERGIE_1 = 'Energie 1',
  ENERGIE_2 = 'Energie 2',
  HORS_ENERGIE_1 = 'Hors Energie 1',
  HORS_ENERGIE_2 = 'Hors Energie 2',
  INTRANTS_1 = 'Intrants 1',
  INTRANTS_2 = 'Intrants 2',
  FUTURS_EMBALLAGES = 'Futurs emballages',
  DECHETS_DIRECTS = 'Déchets directs',
  FRET = 'Fret',
  DEPLACEMENTS = 'Déplacements',
  IMMOBILISATIONS = 'Immobilisations',
  UTILISATION = 'Utilisation',
  FIN_DE_VIE = 'Fin de vie',
  UTILITAIRES = 'Utilitaires',
  RECAP_COE2E = 'Récap CO2e',
  RATIOS = 'Ratios',
  BILAN_GES = 'Bilan GES',
  ISO_14069 = 'ISO 14069',
  GHG_PROTOCOL = 'GHG Protocol',
  CDP_2018 = 'CDP 2018',
  GRAPHIQUES = 'Graphiques',
  FE_ENERGIE = 'FE Energie',
  FE_HORS_ENERGIE = 'FE Hors Energie',
  FE_INTRANTS = 'FE Intrants',
  FE_DECHETS = 'FE Déchets',
  FE_FRETS = 'FE Frets',
  FE_DEPLACEMENTS = 'FE Déplacements',
  FE_IMMOBILISATIONS = 'FE Immobilisations',
  EXPORT_POSTES = 'export postes',
  EXPORT_SOUS_POSTES = 'export sous-postes'
}
