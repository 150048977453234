import { PlusIcon } from '@heroicons/react/24/outline';
import Loading from 'components/Loading';
import Select, { Option } from 'components/Select';
import { FilterForm } from 'queryTypes/form/form.dto';
import { Form } from 'queryTypes/form/form.model';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import formService from 'services/api/forms/services';
import Pagination from 'components/Pagination';
import FormsItem from './Item';
import organizationStore from 'stores/Organization';

const Forms = () => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const [sectorsList, setSectorsList] = useState<Option[]>([]);
  const [pageSizeValue, setPageSizeValue] = useState<number>(10);
  const [filter, setFilter] = useState<FilterForm>({
    page: 1,
    per_page: pageSizeValue,
    sector: '',
    workspaceId: currentOrganization?.id,
    orderByField: 'updatedAt',
    orderByDirection: 'DESC'
  });

  // Query forms
  const { data: forms, refetch, error, loading: isLoading } = formService.getAllPaginated(filter);

  // Query sectors
  const {
    data: sectors,
    refetch: refetchSector,
    error: errorSector,
    loading: isLoadingSector
  } = formService.getSectors();

  const setPageSize = useCallback((pageSize: number) => {
    setPageSizeValue(pageSize);
    setFilter((prevFilter) => ({ ...prevFilter, per_page: pageSize }));
  }, []);

  const setPage = useCallback((page: number) => {
    setFilter((prevFilter) => ({ ...prevFilter, page: page }));
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (errorSector) {
    return <div>Error: {errorSector.message}</div>;
  }

  useEffect(() => {
    if (!isLoadingSector && sectorsList.length === 0) {
      setSectorsList([
        { value: '', label: 'Tous les secteurs' },
        ...sectors?.sectors?.map((sector: string) => ({
          value: sector,
          label: sector
        }))
      ]);
    }
  }, [sectors, sectorsList, isLoadingSector]);

  return (
    <>
      {isLoading || isLoadingSector ? (
        <Loading />
      ) : (
        <>
          <div className=" bg-white mb-20">
            <div className="flex flex-row justify-between items-center mx-auto px-20 pt-10">
              <h1 className="text-3xl font-normal text-black">Questionnaires</h1>
            </div>
            <div className="w-full mt-20 px-4 sm:px-6 lg:px-8">
              <div className="max-w-6xl mx-auto">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <p className="mt-2 text-sm text-gray-700">
                      Liste des questionnaires disponibles{' '}
                      <strong>({forms?.paginatedForms?.totalCount})</strong>
                    </p>
                  </div>
                  <div className="w-56 mt-4 sm:mt-0 sm:flex-none">
                    <Select
                      selected={filter.sector}
                      onChange={(value) => setFilter({ ...filter, sector: value })}
                      options={sectorsList}
                    />
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Link
                      to="/forms/steps/new"
                      className="flex flex-row justify-between items-center space-x-2 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <div>
                        <PlusIcon className="h-5 w-5 inline-block" aria-hidden="true" />
                      </div>
                      <div>Nouveau</div>
                    </Link>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                            >
                              Secteur d&apos;activité
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                            >
                              Nombre de questions
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                            >
                              Clients associés
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                            >
                              Règles
                            </th>
                            <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                              <span className="sr-only">Modifier</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {forms?.paginatedForms.data.map((form: Form, index: number) => (
                            <FormsItem
                              key={index}
                              form={form}
                              refetch={refetch}
                              refetchSector={refetchSector}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {forms?.paginatedForms.totalCount > 0 && (
            <Pagination
              title="questionnaire(s)"
              page={forms?.paginatedForms.page}
              totalCount={forms?.paginatedForms.totalCount}
              pageSize={pageSizeValue}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          )}
        </>
      )}
    </>
  );
};

export default Forms;
