import { useMutation } from '@apollo/client';
import {
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  ExclamationTriangleIcon,
  MinusIcon,
  PencilIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Customer } from 'queryTypes/customer';
import { Form } from 'queryTypes/form/form.model';
import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDestroyForm, useDuplicateForm } from 'services/api/forms/queries';
import { Tooltip } from 'react-tooltip';
import React from 'react';
import ModalDuplicateForm from './ModalDuplicateForm';
import ModalDelete from 'components/ModalDelete';
import ModalMapping from 'components/ModalMapping';

type Props = {
  form: Form;
  refetch: () => void;
  refetchSector: () => void;
};

const FormsItem = (props: Props) => {
  const { form, refetch, refetchSector } = props;
  const [isOpenModalDuplicateForm, setIsOpenModalDuplicateForm] = useState<boolean>(false);
  const [isOpenModalDeleteForm, setIsOpenModalDeleteForm] = useState<boolean>(false);
  const [isOpenModalMappingForm, setIsOpenModalMappingForm] = useState<boolean>(false);

  const navigate = useNavigate();

  const [duplicateForm, { loading: loadingDuplicateForm }] = useMutation(useDuplicateForm);
  const [destroyForm, { loading: loadingDestroyForm }] = useMutation(useDestroyForm);

  const handleDestroyForm = useCallback((form: Form) => {
    if (loadingDestroyForm) return;
    destroyForm({
      variables: {
        formId: form.id
      },
      onCompleted: (data) => {
        if (data && !data.destroyForm.errors) {
          refetch();
          refetchSector();
        }
      }
    });
  }, []);

  const handleDuplicateForm = useCallback((form: Form, sector: string, redirect: boolean) => {
    if (loadingDuplicateForm) return;
    duplicateForm({
      variables: {
        formId: form.id,
        sector
      },
      onCompleted: (data) => {
        if (data && !data.duplicateForm.errors) {
          refetch();
          refetchSector();
          if (redirect) {
            navigate(`/forms/steps/${data.duplicateForm.form.id}`);
          }
        }
      }
    });
  }, []);

  return (
    <>
      <tr className="hidden">
        <td>
          <ModalDuplicateForm
            form={form}
            isOpen={isOpenModalDuplicateForm}
            onClose={() => setIsOpenModalDuplicateForm(false)}
            onSubmit={handleDuplicateForm}
          />
          <ModalDelete
            title="Supprimer un formulaire"
            isOpen={isOpenModalDeleteForm}
            onClose={() => setIsOpenModalDeleteForm(false)}
            onSubmit={() => handleDestroyForm(form)}
          >
            le questionnaire du secteur d&apos;activité {form.sector}
          </ModalDelete>
          <ModalMapping
            isOpen={isOpenModalMappingForm}
            onClose={() => setIsOpenModalMappingForm(false)}
            onSubmit={() => setIsOpenModalMappingForm(false)}
            object={form}
            refetch={refetch}
            nbExcelMapping={form.excelMappings.length}
          />
        </td>
      </tr>
      <tr>
        <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-500">
          {form.sector}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {form.questions.length} question(s)
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {form.customers.length === 0 && (
            <MinusIcon className="h-5 w-5 inline-block text-gray-400" aria-hidden="true" />
          )}
          <div className="flex flex-row items-center space-x-2">
            {form.customers.map((customer: Customer, index: number) => (
              <React.Fragment key={index}>
                <span
                  className="rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 cursor-pointer"
                  data-tooltip-id={`url-tooltip-${index}`}
                  onClick={() => {
                    navigator.clipboard.writeText(`https://app.carbono.eco/forms/${customer.id}`);
                  }}
                >
                  {customer.name}
                </span>
                <Tooltip id={`url-tooltip-${index}`} place="bottom" content="Copier l'URL" />
              </React.Fragment>
            ))}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="flex flex-row justify-start space-x-2 items-center">
            {form.excelMappings.length === 0 && (
              <ExclamationTriangleIcon
                className="h-5 w-5 text-red-600 animate-pulse"
                aria-hidden="true"
              />
            )}
            <p>{form.excelMappings.length} règle(s) de mapping</p>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="flex flex-row justify-between items-center space-x-3">
            <button
              onClick={() => setIsOpenModalDuplicateForm(true)}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-lime-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
            >
              <DocumentDuplicateIcon className="h-5 w-5 inline-block" aria-hidden="true" />
              Dupliquer
            </button>
            <Link
              to={`/forms/steps/${form.id}`}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PencilIcon className="h-5 w-5 inline-block" aria-hidden="true" />
              Modifier
            </Link>
            <button
              className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => setIsOpenModalMappingForm(true)}
            >
              <Cog6ToothIcon className="h-5 w-5 inline-block" aria-hidden="true" />
              Mapping
            </button>
            <button
              onClick={() => setIsOpenModalDeleteForm(true)}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              <XMarkIcon className="h-5 w-5 inline-block" aria-hidden="true" />
              Supprimer
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default FormsItem;
