import { Collaborator } from 'queryTypes/collaborator';
import {
  XMarkIcon,
  PencilIcon,
  CursorArrowRaysIcon,
  ArrowUpOnSquareIcon
} from '@heroicons/react/24/outline';
import { useState, useCallback } from 'react';
import ModalEditCollaborator from '../ModalEditCollaborator';
import ModalDelete from 'components/ModalDelete';
import ModalAssociate from '../ModalAssociate';

type Props = {
  collaborator: Collaborator;
  refetch: () => void;
  onUpdate: (id: string, name: string, role: string, email: string) => void;
  onDestroy: (collaborator: Collaborator) => void;
};

const CollaboratorsItem = (props: Props) => {
  const { collaborator, refetch, onUpdate, onDestroy } = props;

  const [isModalAssociateOpen, setIsModalAssociateOpen] = useState<boolean>(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState<boolean>(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);

  const handleSubmitUpdateCollaborator = useCallback(
    async (id: string, name: string, role: string, email: string) => {
      onUpdate(id, name, role, email);
      setIsModalEditOpen(false);
    },
    [onUpdate]
  );

  const handleDestroyCollaborator = useCallback(
    (collaboratorDestroy: Collaborator) => {
      onDestroy(collaboratorDestroy);
      setIsModalDeleteOpen(false);
    },
    [onDestroy]
  );

  return (
    <>
      <tr className="hidden">
        <td>
          <ModalEditCollaborator
            collaborator={collaborator}
            isOpen={isModalEditOpen}
            onClose={() => setIsModalEditOpen(false)}
            onSubmit={handleSubmitUpdateCollaborator}
          />
          <ModalAssociate
            collaborator={collaborator}
            isOpen={isModalAssociateOpen}
            onClose={() => setIsModalAssociateOpen(false)}
            refetch={refetch}
          />
          <ModalDelete
            title="Supprimer un collaborateur"
            isOpen={isModalDeleteOpen}
            onClose={() => setIsModalDeleteOpen(false)}
            onSubmit={() => handleDestroyCollaborator(collaborator)}
          >
            le collaborateur {collaborator.name}
          </ModalDelete>
        </td>
      </tr>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
          {collaborator.customers.map((customer) => customer.name).join(', ')}
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
          {collaborator.name}
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
          {collaborator.email}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{collaborator.role}</td>
        <td className="flex flex-row justify-start items-center space-x-5 whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <button
            onClick={() => setIsModalEditOpen(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PencilIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            Modifier
          </button>
          {collaborator.customers[0]?.forms && collaborator.customers[0].forms?.length > 0 && (
            <button
              onClick={() => setIsModalAssociateOpen(true)}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-violet-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
            >
              <CursorArrowRaysIcon className="h-5 w-5 inline-block" aria-hidden="true" />
              Associer
            </button>
          )}
          {collaborator.customers[0]?.forms && collaborator.customers[0].forms?.length > 0 && (
            <button
              onClick={() => {
                window.open(
                  `https://app.carbono.eco/forms/collaborators/${collaborator.id}`,
                  '_blank'
                );
              }}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-lime-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
            >
              <ArrowUpOnSquareIcon className="h-5 w-5 inline-block" aria-hidden="true" />
              URL
            </button>
          )}
          <button
            onClick={() => setIsModalDeleteOpen(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            <XMarkIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            Supprimer
          </button>
        </td>
      </tr>
    </>
  );
};
export default CollaboratorsItem;
