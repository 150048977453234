import ModalForm from 'components/ModalForm';
import { SheeValuesPossibleAnswers, SheetValues } from 'components/SheetCreator';
import { useState } from 'react';
import SheetCreatorItemModalAddPossibleAnswerItem from './Item';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  sheetValue: SheetValues;
  onChangeSheetValues: (sheet: SheetValues) => void;
};
const SheetCreatorItemModalAddPossibleAnswer = (props: Props) => {
  const { isOpen, onClose, sheetValue, onChangeSheetValues } = props;

  const [text, setText] = useState<string>('');
  const [value, setValue] = useState<string>('');

  return (
    <ModalForm
      title={`Ajoutez des réponses à la colonne "${sheetValue.name}"`}
      ctaTitle="J'ai terminé"
      size="xl"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onClose()}
    >
      <div className="flex flex-col space-y-5">
        <div>
          <div className="sm:col-span-4">
            <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900">
              Label
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="text"
                onChange={(e) => setText(e.target.value)}
                value={text}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Avion"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="sm:col-span-4">
            <label htmlFor="value" className="block text-sm font-medium leading-6 text-gray-900">
              Valeur
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="value"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Transport Aérien"
              />
            </div>
          </div>
        </div>
        {text && value && (
          <div className="flex justify-end items-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                onChangeSheetValues({
                  ...sheetValue,
                  possibleAnswers: [
                    ...sheetValue.possibleAnswers,
                    {
                      label: text,
                      value: value
                    }
                  ]
                });
                setText('');
                setValue('');
              }}
            >
              Créer la réponse
            </button>
          </div>
        )}
        <div className="mt-8 mb-10 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Label
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Valeur
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Supprimer</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {sheetValue.possibleAnswers.map(
                    (possibleAnswer: SheeValuesPossibleAnswers, index: number) => (
                      <SheetCreatorItemModalAddPossibleAnswerItem
                        key={index}
                        sheetValue={sheetValue}
                        possibleAnswer={possibleAnswer}
                        onChangeSheetValues={onChangeSheetValues}
                      />
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default SheetCreatorItemModalAddPossibleAnswer;
