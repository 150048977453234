import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import GroupCta from 'components/GroupCta';
import { Workspace } from 'queryTypes/workspace';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import { useCallback, useEffect, useState } from 'react';
import Notification from 'components/Notification';
import workspaceService from 'services/api/workspaces/services';
import { ApolloClient } from '@apollo/client';
import { observer } from 'mobx-react';

type Props = {
  clientApollo: ApolloClient<any>;
};

const OrganizationSelector = ({ clientApollo }: Props): JSX.Element => {
  const { currentUser } = authStore;
  const { currentOrganization } = organizationStore;

  const [workspaceId, setWorkspaceId] = useState<string>(currentOrganization?.id || '');
  const [workspaceName, setWorkspaceName] = useState<string>(
    currentOrganization?.name || 'Sélectionnez une organisation'
  );
  const [openNotification, setOpenNotification] = useState(false);
  const {
    data: workspaces,
    loading,
    refetch
  } = workspaceService.getAllWorkspace({ userId: currentUser?.id || '' });

  const selectWorkspace = useCallback(async (id: string, isCreate?: boolean) => {
    await organizationStore.setCurrent(id, clientApollo);
    if (isCreate) {
      refetch();
    }
  }, []);

  const handleChangeWorkspace = useCallback(
    (id: string, name: string, isCreate?: boolean) => {
      setWorkspaceId(id);
      setWorkspaceName(name);
      selectWorkspace(id, isCreate);
    },
    [setWorkspaceId, setWorkspaceName, selectWorkspace, workspaceId, workspaceName]
  );

  useEffect(() => {
    setWorkspaceName(currentOrganization?.name || 'Sélectionnez une organisation ');
  }, [currentOrganization]);

  if (loading || !currentUser) return <></>;

  return (
    <div className="ml-4 flex items-center md:ml-6">
      <Notification
        title="Espace créé"
        message={`L'espace ${workspaceName} a bien été créé`}
        icon="success"
        isOpen={openNotification}
        onClose={() => setOpenNotification(false)}
      />
      <GroupCta
        titleInGroup={workspaceName}
        type="tertiary"
        icon={
          <ChevronUpDownIcon
            className={`h-5 w-5 ${!currentOrganization ? 'text-slate-300' : 'text-white'}`}
            aria-hidden="true"
          />
        }
        classNameMenuButton={`${
          !currentOrganization ? 'bg-transparent text-slate-300' : 'bg-transparent text-white'
        }`}
        classNameMenu={`absolute right-0 z-10 mt-2 -mr-1 w-[200px] ${
          workspaces.workspaces.length > 10 ? 'h-[300px]' : 'h-auto'
        } overflow-y-auto origin-top-right rounded-b-md bg-white text-black hover:text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        items={[
          ...workspaces.workspaces.map((workspaceData: Workspace) => ({
            name: workspaceData.name,
            onClick: () => {
              handleChangeWorkspace(workspaceData.id, workspaceData.name);
            },
            current: workspaceData.id === workspaceId
          }))
        ]}
      />
    </div>
  );
};

export default observer(OrganizationSelector);
