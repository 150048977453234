import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import SheetCreatorItem from './Item';

export type SheeValuesPossibleAnswers = {
  value: string;
  label: string;
};

export type SheetValues = {
  id: number;
  name: string;
  type: 'text' | 'number' | 'multiple_choices';
  possibleAnswers: SheeValuesPossibleAnswers[];
};

type Props = {
  onSheetJson: (sheet: string) => void;
  sheetTemplate?: string;
};

const SheetCreator = (props: Props) => {
  const { onSheetJson, sheetTemplate } = props;

  const sheetTemplateJson = sheetTemplate ? JSON.parse(sheetTemplate) : undefined;

  const [columns, setColumns] = useState<number>(sheetTemplateJson?.columns?.length || 2);
  const [sheetValues, setSheetValues] = useState<SheetValues[]>(
    sheetTemplateJson?.columns ||
      [...Array(columns)].map((v: number, column: number) => ({
        id: column,
        name: `Column ${column}`,
        type: 'text',
        possibleAnswers: []
      }))
  );

  const handleChangeSheetValue = useCallback(
    (sheetValue: SheetValues) => {
      const newSheetValues = [...sheetValues];
      const index = newSheetValues.findIndex((sheet: SheetValues) => sheet.id === sheetValue.id);
      newSheetValues[index] = sheetValue;
      setSheetValues(newSheetValues);
    },
    [sheetValues]
  );

  const handleDeleteSheetValue = useCallback(
    (sheetValue: SheetValues) => {
      const newSheetValues = [...sheetValues];
      const index = newSheetValues.findIndex((sheet: SheetValues) => sheet.id === sheetValue.id);
      newSheetValues.splice(index, 1);
      setSheetValues(newSheetValues);
      setColumns(newSheetValues.length);
    },
    [sheetValues]
  );

  const createSheetJson = useCallback(() => {
    const sheetJson = {
      columns: sheetValues.map((sheetValue: SheetValues) => ({
        id: sheetValue.id,
        name: sheetValue.name,
        type: sheetValue.type,
        possibleAnswers: sheetValue.possibleAnswers
      }))
    };
    onSheetJson(JSON.stringify(sheetJson));
  }, [sheetValues, onSheetJson]);

  const handleAddSheetValue = useCallback(
    (nb: number) => {
      setColumns(nb);
      if (sheetValues.length < nb) {
        const newSheetValues = [...sheetValues];
        for (let i = sheetValues.length; i < nb; i++) {
          newSheetValues.push({
            id: i,
            name: `Column ${i}`,
            type: 'text',
            possibleAnswers: []
          });
        }
        setSheetValues(newSheetValues);
      }
    },
    [columns, sheetValues]
  );

  useEffect(() => {
    if (sheetValues.length > columns) {
      const newSheetValues = [...sheetValues];
      for (let i = sheetValues.length; i > columns; i--) {
        newSheetValues.pop();
      }
      setColumns(newSheetValues.length);
      setSheetValues(newSheetValues);
    }

    createSheetJson();
  }, [sheetValues, columns]);

  return (
    <div className="mt-10 bg-slate-100 p-10 rounded-md border max-w-4xl mx-auto">
      <div className="flex flex-row justify-center space-x-20 items-baseline">
        <div>
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="columns" className="block text-xs font-medium text-gray-900">
              Nombre de colonnes
            </label>
            <input
              type="number"
              id="columns"
              min={1}
              max={20}
              value={columns}
              onChange={(e) => handleAddSheetValue(parseInt(e.target.value))}
              className="block w-36 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
              placeholder="2"
            />
          </div>
        </div>
        {columns > 0 && (
          <div className="grid grid-cols-3 gap-4 w-full">
            {sheetValues.map((sheet: SheetValues, index: number) => (
              <SheetCreatorItem
                key={index}
                sheetValue={sheet}
                onChangeSheetValues={handleChangeSheetValue}
                onDeleteSheetValues={handleDeleteSheetValue}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(SheetCreator);
