import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import Home from 'pages/Home';
import { ApolloClient } from '@apollo/client';
import authStore from 'stores/Auth';
import Layout from 'layout';
import Forms from 'pages/Forms';
import FormsStep from 'pages/Forms/Step';
import Customers from 'pages/Customers';
import organizationStore from 'stores/Organization';
import OrganizationInit from 'layout/OrganizationInit';
import Collaborators from 'pages/Collaborators';
import Catalog from 'pages/Catalog';
import Statistics from 'pages/Statistics';

type Props = {
  clientApollo: ApolloClient<any>;
};

const AppPrivateRoutes = ({ clientApollo }: Props): JSX.Element => {
  authStore.login(clientApollo);
  const { currentOrganization } = organizationStore;

  return (
    <>
      {!currentOrganization ? (
        <Router>
          <OrganizationInit clientApollo={clientApollo} />
        </Router>
      ) : (
        <Router>
          <Layout client={clientApollo}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/forms" element={<Forms />} />
              <Route path="/forms/steps/new" element={<FormsStep client={clientApollo} />} />
              <Route path="/forms/steps/:formId" element={<FormsStep client={clientApollo} />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/collaborators" element={<Collaborators />} />
              <Route path="/catalog" element={<Catalog />} />
              <Route path="/statistics" element={<Statistics />} />
            </Routes>
          </Layout>
        </Router>
      )}
    </>
  );
};

export default observer(AppPrivateRoutes);
