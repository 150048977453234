import AppPrivateRoutes from 'AppPrivateRoutes';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import config from 'config';
import './index.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { setContext } from '@apollo/client/link/context';
import { observer } from 'mobx-react';
import Loading from 'components/Loading';

const App = (): JSX.Element => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout, getIdTokenClaims } =
    useAuth0();

  const [client, setClient] = useState<ApolloClient<any>>();

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !user) {
      logout({ logoutParams: { returnTo: window.location.origin } });
      loginWithRedirect();
    }
  }, [isAuthenticated, user, isLoading]);

  const getAccessToken = useCallback(async () => {
    try {
      const token = await getIdTokenClaims();

      if (token) {
        localStorage.setItem('token', token.__raw);

        const authLink = setContext((_, { headers }) => {
          // get the authentication token from local storage if it exists
          const token = localStorage.getItem('token');

          // return the headers to the context so httpLink can read them
          return {
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : ''
            }
          };
        });

        // Initialize Apollo Client
        const client = new ApolloClient({
          link: authLink.concat(
            new HttpLink({
              uri: config.API_URL_GRAPHQL
            })
          ),
          cache: new InMemoryCache()
        });

        setClient(client);
      }
    } catch (e) {
      console.log(e);
    }
  }, [client, setClient]);

  useEffect(() => {
    getAccessToken();
  }, [isAuthenticated]);

  if (!client || isLoading || !isAuthenticated) return <Loading />;

  return (
    <ApolloProvider client={client}>
      {isAuthenticated && <AppPrivateRoutes clientApollo={client} />}
    </ApolloProvider>
  );
};

export default observer(App);
