import ModalForm from 'components/ModalForm';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (project: any, quantity: number) => void;
  project: any;
};
const ModalView = (props: Props) => {
  const { isOpen, onClose, onSubmit, project } = props;
  const [quantity, setQuantity] = useState<number>(1000);

  return (
    <ModalForm
      title={project.projectName}
      ctaTitle="Acheter avec Carbono"
      size="xl"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit(project, quantity)}
      onLoading={quantity === 0}
      withCloseButton
    >
      <div className="flex flex-col space-y-5">
        <div>
          <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
            Quantité de CO2 à compenser
          </label>
          <input
            type="number"
            name="quantity"
            min={1}
            max={project.amount}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            value={quantity}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Nombre de kg de CO2"
          />
        </div>
        <div className="flex flex-row justify-between items-center space-x-5">
          <div className="relative flex items-start w-1/2">
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Quantité disponible</dt>
                <dd className="text-gray-700">
                  <div className="font-medium text-gray-900">
                    {(project.amount - quantity).toLocaleString()} {project.unit}
                  </div>
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Prix</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="font-medium text-gray-900">
                    {(parseFloat(project.price) * quantity).toLocaleString()} {project.currency}
                  </div>
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Description</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="text-normal">
                    {project.description?.length > 0
                      ? project.description
                      : 'Aucune description disponible'}
                  </div>
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Région</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="text-normal">{project.region}</div>
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Technologie</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="text-normal">{project.technology}</div>
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Type</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="text-normal">{project.type}</div>
                </dd>
              </div>
            </dl>
          </div>
          <div>
            {project.images && project.images.length > 0 && (
              <div className="flex flex-col space-y-5">
                {project.images.map((image: any, index: number) => (
                  <img
                    key={index}
                    src={image.thumbnails[0].url}
                    alt={image.alternativeText}
                    className="w-42 object-cover rounded-md"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalView;
