import { gql } from '@apollo/client';
import { FilterPossibleAnswer } from 'queryTypes/possibleAnswer';

export const useGetAllPossibleAnswer = (options?: FilterPossibleAnswer) => gql`
  query getPossibleAnswer {
    possibleAnswers(filter: "${options?.value ? `value == '${options?.value}'` : ``}") {
      id
      value
      label
    }
  }
`;

export const useCreatePossibleAnswer = gql`
  mutation createPossibleAnswer($possibleAnswer: PossibleAnswerInputType!) {
    createPossibleAnswer(attributes: $possibleAnswer) {
      possibleAnswer {
        id
        value
        label
      }
      errors
    }
  }
`;

export const useUpdatePossibleAnswer = gql`
  mutation updatePossibleAnswer(
    $possibleAnswerId: PossibleAnswerId!
    $possibleAnswer: PossibleAnswerInputType!
  ) {
    updatePossibleAnswer(id: $possibleAnswerId, attributes: $possibleAnswer) {
      possibleAnswer {
        id
      }
      errors
    }
  }
`;

export const useGetPossibleAnswer = (options: FilterPossibleAnswer) => gql`
  query getPossibleAnswer {
    possibleAnswer(id: "${options.id}") {
      id
      value
      label
    }
  }
`;

export const useDestroyPossibleAnswer = gql`
  mutation destroyPossibleAnswer($possibleAnswerId: PossibleAnswerId!) {
    destroyPossibleAnswer(id: $possibleAnswerId) {
      possibleAnswer {
        id
      }
      errors
    }
  }
`;
