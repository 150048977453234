import ModalForm from 'components/ModalForm';
import Select from 'components/Select';
import { Customer } from 'queryTypes/customer';
import { useState } from 'react';
import customerService from 'services/api/customer/services';
import organizationStore from 'stores/Organization';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (customer: string, name: string, role: string, email: string) => void;
};
const ModalAddCollaborator = (props: Props) => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const { isOpen, onClose, onSubmit } = props;
  const [name, setName] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [selected, setSelected] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  // Query customers
  const { data: customers, loading: isLoading } = customerService.getAll({
    workspaceId: currentOrganization?.id
  });

  return (
    <ModalForm
      title={`Créez un nouveau collaborateur`}
      ctaTitle="Ajouter"
      size="md"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit(selected, name, role, email)}
      onLoading={name === '' || role === '' || email === '' || selected === ''}
      withCloseButton
    >
      <div className="flex flex-col space-y-5">
        <div>
          <div className="sm:col-span-4">
            {!isLoading && customers && (
              <Select
                selected={selected}
                options={[
                  {
                    label: 'Sélectionnez un client',
                    value: ''
                  },
                  ...customers?.customers?.map((customer: Customer) => ({
                    value: customer.id,
                    label: customer.name
                  }))
                ]}
                onChange={(value) => setSelected(value)}
              />
            )}
          </div>
        </div>
        <div>
          <div className="sm:col-span-4">
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
              Nom du collaborateur
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="John Doe"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="sm:col-span-4">
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
              Email du collaborateur
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="john.doe@gmail.com"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start items-center space-x-5">
          <div className="sm:col-span-4">
            <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
              Role
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="role"
                onChange={(e) => setRole(e.target.value)}
                value={role}
                className="block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Directeur commercial"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default ModalAddCollaborator;
