import { Customer } from 'queryTypes/customer';
import { ArrowUpOnSquareIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import ModalEditStatistic from '../ModalEditStatistic';
import { Form } from 'queryTypes/form';
import { useCreateStat, useUpdateStat } from 'services/api/stat/queries';
import { Camembert, Bar, Equivalent, Intensity, Year } from 'queryTypes/stat/stat.model';

type Props = {
  customer: Customer;
  form: Form;
  refetch: () => void;
};

const StatisticsItem = (props: Props) => {
  const { customer, form, refetch } = props;

  const [isModalEditOpen, setIsModalEditOpen] = useState<boolean>(false);
  const [updateStat, { loading: isLoadingUpdateStat }] = useMutation(useUpdateStat);
  const [createStat, { loading: isLoadingCreateStat }] = useMutation(useCreateStat);

  const handleSubmitUpdateStatistic = useCallback(
    async (
      camembert: Camembert,
      bar: Bar,
      equivalent: Equivalent,
      intensity: Intensity,
      year: Year
    ) => {
      if (isLoadingUpdateStat || isLoadingCreateStat) return;
      if (form.stats[0]?.id) {
        updateStat({
          variables: {
            statId: form.stats[0].id,
            stat: {
              camembert: JSON.stringify(camembert),
              bar: JSON.stringify(bar),
              equivalent: JSON.stringify(equivalent),
              intensity: JSON.stringify(intensity),
              year: JSON.stringify(year),
              formId: form.id
            }
          },
          onCompleted: (data) => {
            if (data && !data.updateStat.errors) {
              setIsModalEditOpen(false);
              refetch();
            }
          }
        });
      } else {
        createStat({
          variables: {
            stat: {
              formId: form.id,
              camembert: JSON.stringify(camembert),
              bar: JSON.stringify(bar),
              equivalent: JSON.stringify(equivalent),
              intensity: JSON.stringify(intensity),
              year: JSON.stringify(year)
            }
          },
          onCompleted: (data) => {
            if (data && !data.createStat.errors) {
              setIsModalEditOpen(false);
              refetch();
            }
          }
        });
      }
    },
    [isLoadingUpdateStat, isLoadingCreateStat, form]
  );

  return (
    <>
      <tr className="hidden">
        <td>
          <ModalEditStatistic
            customer={customer}
            isOpen={isModalEditOpen}
            onClose={() => setIsModalEditOpen(false)}
            onSubmit={handleSubmitUpdateStatistic}
          />
        </td>
      </tr>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
          {customer.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{form.sector}</td>
        <td className="flex flex-row justify-start items-center space-x-2 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <button
            onClick={() => setIsModalEditOpen(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PencilIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            Modifier
          </button>
          <button
            onClick={() => {
              window.open(`https://app.carbono.eco/footprint/${customer.id}`, '_blank');
            }}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-lime-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
          >
            <ArrowUpOnSquareIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            URL
          </button>
        </td>
      </tr>
    </>
  );
};
export default StatisticsItem;
