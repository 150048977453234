import { useMutation } from '@apollo/client';
import { ExcelMapping } from 'queryTypes/excelMapping';
import { useCallback, useState } from 'react';
import { useDestroyExcelMapping } from 'services/api/excelMapping/queries';
import { ArrowDownIcon, ArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ModalDelete from 'components/ModalDelete';

type Props = {
  excelMapping: ExcelMapping;
  nbExcelMapping: number;
  onChangePosition: (excelMapping: ExcelMapping, orderIndex: number) => void;
  refetch: () => void;
};

const ModalMappingItem = (props: Props) => {
  const { excelMapping, nbExcelMapping, onChangePosition, refetch } = props;

  const [isModalOpenDelete, setIsModalOpenDelete] = useState<boolean>(false);

  const [destroyExcelMapping, { loading: isLoadingDestroyExcelMapping }] =
    useMutation(useDestroyExcelMapping);

  const handleChangePosition = useCallback(
    (position: 'down' | 'up') => {
      if (position === 'down') {
        if (excelMapping.orderIndex === 1) {
          onChangePosition(excelMapping, excelMapping.orderIndex + 1);
        }
        if (excelMapping.orderIndex > 1 && excelMapping.orderIndex < nbExcelMapping) {
          onChangePosition(excelMapping, excelMapping.orderIndex + 1);
        }
      }
      if (position === 'up') {
        if (excelMapping.orderIndex === nbExcelMapping && nbExcelMapping !== 1) {
          onChangePosition(excelMapping, excelMapping.orderIndex - 1);
        }
        if (excelMapping.orderIndex > 1 && excelMapping.orderIndex < nbExcelMapping) {
          onChangePosition(excelMapping, excelMapping.orderIndex - 1);
        }
      }
    },
    [excelMapping]
  );

  const handleDestroyExcelMapping = useCallback(
    (excelMapping: ExcelMapping) => {
      if (isLoadingDestroyExcelMapping) return;
      destroyExcelMapping({
        variables: {
          excelMappingId: excelMapping.id
        },
        onCompleted: (data) => {
          if (data && !data.destroyExcelMapping.errors) {
            refetch();
          }
        }
      });
    },
    [isLoadingDestroyExcelMapping]
  );

  return (
    <>
      <tr className="hidden">
        <td>
          <ModalDelete
            title="Supprimer une règle"
            isOpen={isModalOpenDelete}
            onClose={() => setIsModalOpenDelete(false)}
            onSubmit={() => {
              setIsModalOpenDelete(false);
              handleDestroyExcelMapping(excelMapping);
            }}
          >
            la règle{' '}
            <span className="font-bold">
              {excelMapping.excelBaseColumn} {excelMapping.excelBaseLine}
            </span>{' '}
            avec <code className="font-bold">{excelMapping.value}</code> en position{' '}
            <span className="font-bold">{excelMapping.orderIndex}</span>{' '}
            {excelMapping.insert ? (
              <>
                et en <span className="font-bold">insertion</span>
              </>
            ) : (
              ''
            )}
          </ModalDelete>
        </td>
      </tr>
      <tr>
        <td className="flex flex-row justify-start items-center space-x-1 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <div>{excelMapping.orderIndex}</div>
          <div>
            {excelMapping.orderIndex === 1 && nbExcelMapping !== 1 && (
              <ArrowDownIcon
                className="w-3 h-3 text-gray-700 cursor-pointer"
                onClick={() => handleChangePosition('down')}
                aria-hidden="true"
              />
            )}
            {excelMapping.orderIndex > 1 && excelMapping.orderIndex < nbExcelMapping && (
              <div className="flex flex-row justify-start items-center">
                <ArrowUpIcon
                  className="w-3 h-3 text-gray-700 cursor-pointer"
                  onClick={() => handleChangePosition('up')}
                  aria-hidden="true"
                />
                <ArrowDownIcon
                  className="w-3 h-3 text-gray-700 cursor-pointer"
                  onClick={() => handleChangePosition('down')}
                  aria-hidden="true"
                />
              </div>
            )}
            {excelMapping.orderIndex >= nbExcelMapping &&
              excelMapping.orderIndex !== 1 &&
              nbExcelMapping !== 1 && (
                <ArrowUpIcon
                  className="w-3 h-3 text-gray-700 cursor-pointer"
                  onClick={() => handleChangePosition('up')}
                  aria-hidden="true"
                />
              )}
          </div>
        </td>

        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-0">
          Remplir{' '}
          <span className="font-bold">
            {excelMapping.excelBaseColumn} {excelMapping.excelBaseLine}
          </span>{' '}
          avec <code className="font-bold">{excelMapping.value}</code> en position{' '}
          <span className="font-bold">{excelMapping.orderIndex}</span>{' '}
          {excelMapping.insert ? (
            <>
              et en <span className="font-bold">insertion</span>
            </>
          ) : (
            ''
          )}
        </td>
        <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <button
            onClick={() => setIsModalOpenDelete(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            <XMarkIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            Supprimer
          </button>
        </td>
      </tr>
    </>
  );
};

export default ModalMappingItem;
