import ModalForm from 'components/ModalForm';
import { Question } from 'queryTypes/question';
import FormsStepQuestionsNewQuestion from '../../NewQuestion';
import { Type } from 'enums/type';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    question: Question,
    text: string,
    helpMessage: string,
    estimatedTimeToAnswer: number,
    answerType: Type.INTEGER | Type.STRING | Type.BOOLEAN | Type.MULTIPLE_CHOICES | Type.SHEET,
    sheetTemplate: string,
    sheetFormula: string
  ) => void;
  question: Question;
  refetch: () => void;
};
const ModalEdit = (props: Props) => {
  const { isOpen, onClose, onSubmit, question, refetch } = props;

  return (
    <ModalForm
      title={`Modifier la question "${question.text}"`}
      size="xl"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => {}}
    >
      <FormsStepQuestionsNewQuestion refetch={refetch} question={question} onSubmit={onSubmit} />
    </ModalForm>
  );
};

export default ModalEdit;
