import { Customer } from 'queryTypes/customer';
import ModalAssociate from '../ModalAssociate';
import {
  XMarkIcon,
  FireIcon,
  ArrowUpOnSquareIcon,
  CursorArrowRaysIcon,
  PencilIcon,
  EyeIcon
} from '@heroicons/react/24/outline';
import { useState, useCallback } from 'react';
import { useDestroyCustomer, useUpdateCustomer } from 'services/api/customer/queries';
import { useMutation } from '@apollo/client';
import { handleUpload } from 'utils';
import ModalEditCustomer from '../ModalEditCustomer';
import ModalDelete from 'components/ModalDelete';
import ModalViewAnswers from '../ModalViewAnswers';

type Props = {
  customer: Customer;
  refetch: () => void;
};

const CustomersItem = (props: Props) => {
  const { customer, refetch } = props;

  const [isModalViewAnswersOpen, setIsModalViewAnswersOpen] = useState<boolean>(false);
  const [isModalAssociateOpen, setIsModalAssociateOpen] = useState<boolean>(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState<boolean>(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [updateCustomer, { loading: isLoadingUpdateCustomer }] = useMutation(useUpdateCustomer);
  const [destroyCustomer, { loading: isLoadingDestroyCustomer }] = useMutation(useDestroyCustomer);

  const handleSubmitAssociateCustomer = useCallback((formId: string, customer: Customer) => {
    if (isLoadingUpdateCustomer) return;
    updateCustomer({
      variables: {
        customerId: customer.id,
        customer: {
          formIds: [formId]
        }
      },
      onCompleted: (data: { updateCustomer: { errors: any } }) => {
        if (data && !data.updateCustomer.errors) {
          setIsModalAssociateOpen(false);
          refetch();
        }
      }
    });
  }, []);

  const handleSubmitUpdateCustomer = useCallback(
    async (
      name: string,
      email: string,
      phone: string,
      address1: string,
      zipCode: string,
      city: string,
      country: string,
      address2?: string,
      selectedFile?: any
    ) => {
      if (isLoadingUpdateCustomer) return;
      let avatarUrl = '';
      if (selectedFile) {
        avatarUrl = await handleUpload(selectedFile);
      }
      updateCustomer({
        variables: {
          customerId: customer.id,
          customer: {
            name,
            email,
            phone,
            address1,
            address2,
            zipCode,
            city,
            country,
            avatarUrl: avatarUrl ?? customer.avatarUrl
          }
        },
        onCompleted: (data) => {
          if (data && !data.updateCustomer.errors) {
            setIsModalEditOpen(false);
            refetch();
          }
        }
      });
    },
    [isLoadingUpdateCustomer]
  );

  const handleDestroyCustomer = useCallback((customer: Customer) => {
    if (isLoadingDestroyCustomer) return;
    destroyCustomer({
      variables: {
        customerId: customer.id
      },
      onCompleted: (data: { destroyCustomer: { errors: any } }) => {
        if (data && !data.destroyCustomer.errors) {
          setIsModalDeleteOpen(false);
          refetch();
        }
      }
    });
  }, []);

  return (
    <>
      <tr className="hidden">
        <td>
          {isModalViewAnswersOpen && (
            <ModalViewAnswers
              customer={customer}
              isOpen={isModalViewAnswersOpen}
              onClose={() => setIsModalViewAnswersOpen(false)}
            />
          )}
          {isModalAssociateOpen && (
            <ModalAssociate
              customer={customer}
              isOpen={isModalAssociateOpen}
              onClose={() => setIsModalAssociateOpen(false)}
              onSubmit={handleSubmitAssociateCustomer}
            />
          )}
          {isModalEditOpen && (
            <ModalEditCustomer
              customer={customer}
              isOpen={isModalEditOpen}
              onClose={() => setIsModalEditOpen(false)}
              onSubmit={handleSubmitUpdateCustomer}
            />
          )}
          {isModalDeleteOpen && (
            <ModalDelete
              title="Supprimer un client"
              isOpen={isModalDeleteOpen}
              onClose={() => setIsModalDeleteOpen(false)}
              onSubmit={() => handleDestroyCustomer(customer)}
              onLoading={isLoadingDestroyCustomer}
            >
              le client {customer.name}
            </ModalDelete>
          )}
        </td>
      </tr>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
          {customer.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{customer.email}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{customer.phone}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {customer.forms.length} questionnaire(s)
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {customer.answers?.length > 0 ? (
            <button
              onClick={() => setIsModalViewAnswersOpen(true)}
              className="inline-flex items-center gap-x-1.5 text-indigo-600 px-2.5 py-1.5 text-sm font-semibold hover:text-indigo-500"
            >
              <EyeIcon className="h-5 w-5 inline-block" aria-hidden="true" />
              {customer.answers.length} réponse(s)
            </button>
          ) : (
            <>-</>
          )}
        </td>
        <td className="flex flex-row justify-between items-center space-x-2 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <button
            onClick={() => setIsModalEditOpen(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PencilIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            Modifier
          </button>
          {customer.forms.length > 0 ? (
            <button
              onClick={() => {
                window.open(`https://app.carbono.eco/forms/${customer.id}`, '_blank');
              }}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-lime-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
            >
              <ArrowUpOnSquareIcon className="h-5 w-5 inline-block" aria-hidden="true" />
              URL
            </button>
          ) : (
            <button
              onClick={() => setIsModalAssociateOpen(true)}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-violet-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
            >
              <CursorArrowRaysIcon className="h-5 w-5 inline-block" aria-hidden="true" />
              Associer
            </button>
          )}
          <button
            onClick={() => {}}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-orange-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
          >
            <FireIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            Générer
          </button>
          <button
            onClick={() => setIsModalDeleteOpen(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            <XMarkIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            Supprimer
          </button>
        </td>
      </tr>
    </>
  );
};
export default CustomersItem;
