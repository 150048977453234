import { useCallback, useEffect, useMemo, useState } from 'react';
import FormsStepSectors from './Sectors';
import FormsStepQuestions from './Questions';
import { ApolloClient, useMutation } from '@apollo/client';
import { useCreateForm, useGetForm, useUpdateForm } from 'services/api/forms/queries';
import { Form } from 'queryTypes/form';
import FormsStepGenerateCustomer from './GenerateCustomer';
import { useParams } from 'react-router-dom';
import organizationStore from 'stores/Organization';

type Props = {
  client: ApolloClient<any>;
};

const FormsStep = (props: Props) => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const { client } = props;
  const { formId } = useParams();
  const [stepPosition, setStepPosition] = useState<number>(1);
  const [form, setForm] = useState<Form>();

  const steps = useMemo(
    () =>
      formId
        ? [
            {
              position: 1,
              title: `Modifiez le secteur d'activité`
            },
            {
              position: 2,
              title: `Ajoutez des questions`
            },
            {
              position: 3,
              title: 'Générez une URL pour un client'
            }
          ]
        : [
            {
              position: 1,
              title: 'Créez un secteur d&apos;activité'
            },
            {
              position: 2,
              title: 'Ajoutez des questions'
            },
            {
              position: 3,
              title: 'Générez une URL pour un client'
            }
          ],
    []
  );

  const step = steps.find((step) => step.position === stepPosition);

  const [createForm, { loading: isLoadingCreateForm }] = useMutation(useCreateForm);
  const [updateForm, { loading: isLoadingUpdateForm }] = useMutation(useUpdateForm);

  const handleSubmitSector = useCallback(
    (value: string) => {
      if (!currentOrganization || isLoadingCreateForm || isLoadingUpdateForm) return;
      if (formId) {
        updateForm({
          variables: {
            formId: formId,
            form: {
              sector: value
            }
          },
          onCompleted: (data) => {
            if (data && !data.updateForm.errors) {
              setForm(data.updateForm.form);
              client.restore('forms');
              nextStep();
            }
          }
        });
      } else {
        createForm({
          variables: {
            form: {
              workspaceId: currentOrganization.id,
              sector: value
            }
          },
          onCompleted: (data) => {
            if (data && !data.createForm.errors) {
              setForm(data.createForm.form);
              client.restore('forms');
              nextStep();
            }
          }
        });
      }
    },
    [formId, currentOrganization, isLoadingCreateForm, isLoadingUpdateForm]
  );

  const nextStep = useCallback(() => {
    setStepPosition(stepPosition + 1 >= steps.length ? steps.length : stepPosition + 1);
  }, [stepPosition]);

  useEffect(() => {
    if (formId) {
      client
        .query({
          query: useGetForm({ id: formId, workspaceId: currentOrganization?.id })
        })
        .then((response) => {
          if (response.data) {
            setForm(response.data.form);
          }
        });
    }
  }, [formId]);

  return (
    <>
      <div className="bg-white">
        <div className="flex flex-row justify-between items-center mx-auto px-20 py-10">
          {step && (
            <h1
              className="text-3xl font-normal text-black"
              dangerouslySetInnerHTML={{ __html: step.title }}
            />
          )}
        </div>
        <div className="w-full">
          {stepPosition === 1 && formId && form && (
            <FormsStepSectors onSubmit={handleSubmitSector} defaultSelected={form?.sector} />
          )}
          {stepPosition === 1 && !formId && <FormsStepSectors onSubmit={handleSubmitSector} />}
          {stepPosition === 2 && form && formId && (
            <FormsStepQuestions
              form={form}
              onSubmit={nextStep}
              disabled={formId ? true : false}
              client={client}
            />
          )}
          {stepPosition === 2 && form && !formId && (
            <FormsStepQuestions form={form} onSubmit={nextStep} disabled={false} client={client} />
          )}
          {stepPosition === 3 && form && !formId && (
            <FormsStepGenerateCustomer form={form} client={client} />
          )}
          {stepPosition === 3 && form && formId && (
            <FormsStepGenerateCustomer form={form} client={client} />
          )}
        </div>
      </div>
    </>
  );
};

export default FormsStep;
