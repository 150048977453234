import Loading from 'components/Loading';
import ModalForm from 'components/ModalForm';
import { Customer } from 'queryTypes/customer';
import { FilterForm, Form } from 'queryTypes/form';
import { useCallback, useEffect, useState } from 'react';
import formService from 'services/api/forms/services';
import { RadioGroup } from '@headlessui/react';
import {
  CheckCircleIcon,
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon
} from '@heroicons/react/20/solid';
import organizationStore from 'stores/Organization';

type Props = {
  customer: Customer;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formId: string, customer: Customer) => void;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const ModalAssociate = (props: Props) => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const { isOpen, onClose, onSubmit, customer } = props;

  const [filter, setFilter] = useState<FilterForm>({
    page: 1,
    per_page: 6,
    workspaceId: currentOrganization?.id,
    orderByField: 'updatedAt',
    orderByDirection: 'DESC'
  });

  // Query forms
  const { data: forms, loading: isLoading } = formService.getAllPaginated(filter);
  const [formId, setFormId] = useState<string>('');

  const setPage = useCallback((page: number) => {
    setFilter((prevFilter) => ({ ...prevFilter, page: page }));
  }, []);

  useEffect(() => {
    if (forms?.paginatedForms?.data?.length) {
      setFormId(forms?.paginatedForms?.data[0]?.id);
    }
  }, [forms]);

  return (
    <ModalForm
      title={`Associez un questionnaire à un client`}
      ctaTitle="J'ai terminé"
      size="md"
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit(formId, customer)}
      onLoading={isLoading}
      withCloseButton
    >
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col space-y-5">
          <div>
            <RadioGroup value={formId} onChange={setFormId}>
              <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                {forms?.paginatedForms?.data?.map((form: Form, index: number) => (
                  <RadioGroup.Option
                    key={index}
                    value={form.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                        'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className="block text-sm font-medium text-gray-900"
                            >
                              {form.sector}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className="mt-1 flex items-center text-sm text-gray-500"
                            >
                              {form.id}
                            </RadioGroup.Description>
                            <RadioGroup.Description
                              as="span"
                              className="mt-6 text-sm font-medium text-gray-900"
                            >
                              {form.questions.length} questions
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <CheckCircleIcon
                          className={classNames(
                            !checked ? 'invisible' : '',
                            'h-5 w-5 text-indigo-600'
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            active ? 'border' : 'border-2',
                            checked ? 'border-indigo-600' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      )}
      <div className="mt-10 flex flex-row justify-between items-center">
        <button
          type="button"
          className={`justify-start inline-flex px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-200 ${
            (filter?.page ?? 1) <= 1 ? 'opacity-0' : 'opacity-100'
          }`}
          onClick={() => setPage((filter?.page ?? 1) - 1)}
          disabled={(filter?.page ?? 1) <= 1}
        >
          <ArrowSmallLeftIcon className="h-5 w-5" aria-hidden="true" />
          Précédent
        </button>
        <button
          type="button"
          className={`justify-end inline-flex px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-200 ${
            (filter?.page ?? 1) >=
            forms?.paginatedForms?.totalCount / forms?.paginatedForms?.perPage
              ? 'opacity-0'
              : 'opacity-100'
          }`}
          onClick={() => setPage((filter?.page ?? 1) + 1)}
          disabled={
            (filter?.page ?? 1) >=
            forms?.paginatedForms?.totalCount / forms?.paginatedForms?.perPage
          }
        >
          Suivant
          <ArrowSmallRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </ModalForm>
  );
};

export default ModalAssociate;
