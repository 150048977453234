import Loading from 'components/Loading';
import { Customer } from 'queryTypes/customer';
import { FilterCustomer } from 'queryTypes/customer/customer.dto';
import React, { useCallback, useState } from 'react';
import Pagination from 'components/Pagination';
import customerService from 'services/api/customer/services';
import { useSearchParams } from 'react-router-dom';
import organizationStore from 'stores/Organization';
import { Form } from 'queryTypes/form';
import StatisticsItem from './Item';

const Statistics = () => {
  const { currentOrganization } = organizationStore;
  if (!currentOrganization) return null;

  const [queryParameters] = useSearchParams();
  const [pageSizeValue, setPageSizeValue] = useState<number>(10);
  const [filter, setFilter] = useState<FilterCustomer>({
    page: 1,
    per_page: pageSizeValue,
    workspaceId: currentOrganization?.id,
    orderByField: 'updatedAt',
    orderByDirection: 'DESC',
    name: queryParameters.get('search') ?? ''
  });

  // Query customers
  const {
    data: customers,
    refetch,
    error,
    loading: isLoading
  } = customerService.getAllPaginated(filter);

  const setPageSize = useCallback((pageSize: number) => {
    setPageSizeValue(pageSize);
    setFilter((prevFilter) => ({ ...prevFilter, per_page: pageSize }));
  }, []);

  const setPage = useCallback((page: number) => {
    setFilter((prevFilter) => ({ ...prevFilter, page: page }));
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {isLoading ? (
        <div className="h-5">
          <Loading />
        </div>
      ) : (
        <>
          <div className=" bg-white mb-20">
            <div className="flex flex-row justify-between items-center mx-auto px-20 pt-10">
              <h1 className="text-3xl font-normal text-black">Statistiques</h1>
            </div>
            <div className="w-full mt-20 px-4 sm:px-6 lg:px-8">
              <div className="max-w-6xl mx-auto">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <p className="mt-2 text-sm text-gray-700">
                      Liste des questionnaires client{' '}
                      <strong>({customers?.paginatedCustomers?.totalCount})</strong>
                    </p>
                  </div>
                  <div className="w-56 mt-4 sm:mt-0 sm:flex-none">
                    <input
                      type="text"
                      name="name"
                      onChange={(e) =>
                        setFilter((prevFilter) => ({ ...prevFilter, name: e.target.value }))
                      }
                      value={filter.name}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Indiquez un nom de client"
                    />
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                            >
                              Client
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                            >
                              Questionnaire
                            </th>
                            <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                              <span className="sr-only">Modifier</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {customers?.paginatedCustomers.data.map(
                            (customer: Customer, index: number) => (
                              <React.Fragment key={index}>
                                {customer.forms.map((form: Form) => (
                                  <StatisticsItem
                                    customer={customer}
                                    form={form}
                                    key={index}
                                    refetch={refetch}
                                  />
                                ))}
                              </React.Fragment>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {customers?.paginatedCustomers.totalCount > 0 && (
            <Pagination
              title="clients(s)"
              page={customers?.paginatedCustomers.page}
              totalCount={customers?.paginatedCustomers.totalCount}
              pageSize={pageSizeValue}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          )}
        </>
      )}
    </>
  );
};

export default Statistics;
