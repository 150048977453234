import { XMarkIcon } from '@heroicons/react/24/outline';
import ModalDelete from 'components/ModalDelete';
import { SheeValuesPossibleAnswers, SheetValues } from 'components/SheetCreator';
import { Fragment, useState } from 'react';

type Props = {
  sheetValue: SheetValues;
  possibleAnswer: SheeValuesPossibleAnswers;
  onChangeSheetValues: (sheet: SheetValues) => void;
};

const SheetCreatorItemModalAddPossibleAnswerItem = (props: Props) => {
  const { sheetValue, possibleAnswer, onChangeSheetValues } = props;
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);

  return (
    <Fragment>
      <tr className="hidden">
        <td>
          <ModalDelete
            title="Supprimer une réponse"
            isOpen={isOpenModalDelete}
            onClose={() => setIsOpenModalDelete(false)}
            onSubmit={() => {
              const newPossibleAnswers = sheetValue.possibleAnswers.filter(
                (newPossibleAnswer: SheeValuesPossibleAnswers) =>
                  newPossibleAnswer.label !== possibleAnswer.label
              );
              console.log(sheetValue.possibleAnswers, newPossibleAnswers);
              onChangeSheetValues({
                ...sheetValue,
                possibleAnswers: newPossibleAnswers
              });
              setIsOpenModalDelete(false);
            }}
          >
            la réponse {possibleAnswer.label}
          </ModalDelete>
        </td>
      </tr>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          {possibleAnswer.label}
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          {possibleAnswer.value}
        </td>
        <td className="text-right whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <button
            onClick={() => setIsOpenModalDelete(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            <XMarkIcon className="h-5 w-5 inline-block" aria-hidden="true" />
            Supprimer
          </button>
        </td>
      </tr>
    </Fragment>
  );
};
export default SheetCreatorItemModalAddPossibleAnswerItem;
