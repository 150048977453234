import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

type Props = {
  title: string;
  message?: string;
  icon: 'success' | 'error';
  isOpen: boolean;
  onClose: () => void;
};

const Notification = (props: Props) => {
  const { title, message, icon, isOpen, onClose } = props;

  return (
    <>
      <div
        aria-live="off"
        className="z-40 pointer-events-none w-full flex items-end px-4 py-6 p-6 fixed bottom-0 right-0"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={isOpen}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {icon === 'success' ? (
                      <CheckCircleIcon className="h-6 w-6 text-s-success" aria-hidden="true" />
                    ) : (
                      <ExclamationCircleIcon className="h-6 w-6 text-s-error" aria-hidden="true" />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-black">{title}</p>
                    {message && <p className="mt-1 text-sm text-black">{message}</p>}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Notification;
