import { User } from 'queryTypes/user';
import { runInAction, makeAutoObservable } from 'mobx';
import { ApolloClient, gql } from '@apollo/client';
import organizationStore from 'stores/Organization';

const initialUser: User | null = (() => {
  try {
    return JSON.parse(window.localStorage.getItem('currentUser') || 'null');
  } catch (error) {
    return null;
  }
})();

class AuthStore {
  currentId: string | null = initialUser?.id || null;

  currentUser: User | null = initialUser
    ? {
        id: initialUser.id,
        firstName: initialUser.lastName,
        lastName: initialUser.firstName,
        email: initialUser.email,
        workspaces: initialUser.workspaces
      }
    : null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchMe(clientApollo: ApolloClient<any>) {
    const { currentOrganizationId } = organizationStore;

    try {
      clientApollo
        .query({
          query: gql`
            query getMe {
              me {
                id
                firstName
                lastName
                email
                workspaces {
                  id
                  name
                  createdAt
                  updatedAt
                }
              }
            }
          `,
          fetchPolicy: 'no-cache'
        })
        .then((result: any) => {
          window.localStorage.setItem(
            'currentUser',
            JSON.stringify({
              ...result.data.me
            })
          );

          runInAction(() => {
            this.currentUser = {
              id: result.data.me.id,
              firstName: result.data.me.firstName,
              lastName: result.data.me.lastName,
              email: result.data.me.email,
              workspaces: result.data.me.workspaces
            };
            this.currentId = result.data.me.id;
            if (!currentOrganizationId && result.data.me.workspaces.length > 0) {
              organizationStore.setCurrent(result.data.me.workspaces[0].id, clientApollo);
            }
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  login(clientApollo: ApolloClient<any>) {
    if (this.currentUser) return;
    this.fetchMe(clientApollo);
  }

  refresh(clientApollo: ApolloClient<any>) {
    this.fetchMe(clientApollo);
  }

  logout() {
    window.localStorage.removeItem('currentUser');
    window.localStorage.removeItem('currentOrganization');
    this.currentUser = null;
    this.currentId = null;
  }

  resetCurrent() {
    this.currentUser = null;
  }
}

export default new AuthStore();
export { AuthStore };
